import React, { useState, useEffect } from 'react';
import { fetchBoards, deleteBoard, fetchPosts } from '../../api';
import { useNavigate, Link } from 'react-router-dom';

import Layout from '../../components/layout/Layout';
import BoardList from '../../components/board/BoardList';

const App = () => {
  const [boards, setBoards] = useState([]);
  const [postCounts, setPostCounts] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    loadBoards();
    loadPostCounts();
  }, []);

  const loadBoards = () => {
    fetchBoards().then((response) => {
      setBoards(response.data);
    }).catch((error) => {
      console.error("Error loading Boards:", error);
    });
  };
  
  const loadPostCounts = () => {
    fetchPosts().then((response) => {
      const counts = response.data.reduce((acc, post) => {
        acc[post.category_id] = (acc[post.category_id] || 0) + 1;
        return acc;
      }, {});
      setPostCounts(counts);
    }).catch((error) => {
      console.error("Error loading posts:", error);
    });
  };

  const handleEditBoard = (id) => {
    navigate(`/dashboard/board/modify/${id}`);
  };


  const handleDeleteBoard = (id) => {
    deleteBoard(id).then(() => {
      loadBoards();
    }).catch((error) => {
      console.error("Error deleting Boards:", error);
    });
  };

  return (
    <>
      <Layout />
      <div className='contents'>
        <div className='titleArea'>게시판 관리</div>
        <div className='subCate'>
          <Link to="/dashboard/board/PostList">게시물 관리</Link>
          <Link to="/dashboard/board/list" className='selected'>게시판 설정</Link>
        </div>

        <div className='util-wrap'>
          <div className='totalCnt'>Total {boards.length}</div>
          <div className='button-wrap'><Link to="/dashboard/board/write">게시판 등록</Link></div>
        </div>
        <div className='table-wrap'>
          <div className='table-head'>
            <div className='idx'>No</div>
            <div className='category'>분류</div> 
            <div className='title'>게시판명</div> 
            <div className='ttCnt'>게시글 수</div>
            <div className='controll'>관리</div>
          </div>
          <BoardList boards={boards} postCounts={postCounts} onEdit={handleEditBoard} onDelete={handleDeleteBoard} />
        </div>
      </div>
    </>
  );
};

export default App;