import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { adminLogin } from '../api';  // API 메서드 임포트
import './AdminLogin.css';  // 스타일링 파일
const AdminLogin = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const response = await adminLogin({ username, password });
      localStorage.setItem('adminToken', response.data.token);  // JWT 토큰을 로컬스토리지에 저장
      navigate('/dashboard/category/game');  // 대시보드로 이동
    } catch (error) {
      setError('Invalid username or password');
    }
  };

  return (
    <div className="admin-login-container">
      <h2>관리자페이지 로그인</h2>
      {error && <p className="error-message">{error}</p>}
      <form onSubmit={handleLogin}>
        <div className="form-group">
          <label htmlFor="username">아이디</label>
          <input
            type="text"
            id="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">비밀번호</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit">관리자 로그인</button>
      </form>
    </div>
  );
};

export default AdminLogin;