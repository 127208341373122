import React, { useState, useEffect } from 'react';
import { fetchPlayers, fetchSettingsByPlayerId, deletePlayer, fetchTeams, fetchGames } from '../../api';
import { useNavigate, Link } from 'react-router-dom';

import Layout from '../../components/layout/Layout';
import PlayerList from '../../components/player/playerList';

const App = () => {
  const [players, setPlayers] = useState([]);
  const [teams, setTeams] = useState([]);
  const [games, setGames] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState('');
  const [selectedGame, setSelectedGame] = useState('');
  const [searchName, setSearchName] = useState('');
  const [filteredPlayers, setFilteredPlayers] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    loadPlayers();
    loadTeams();
    loadGames();
  }, []);

  const loadPlayers = () => {
    fetchPlayers().then((response) => {
      setPlayers(response.data);
      setFilteredPlayers(response.data);
    }).catch((error) => {
      console.error("Error loading players:", error);
    });
  };
  
  const loadTeams = () => {
    fetchTeams().then(response => {
      setTeams(response.data);
    }).catch(error => {
      console.error('Error loading teams:', error);
    });
  };

  const loadGames = () => {
    fetchGames().then(response => {
      setGames(response.data);
    }).catch(error => {
      console.error('Error loading games:', error);
    });
  };

  const handleFilter = () => {
    const newFilteredPlayers = players.filter(player => {
      const matchTeam = selectedTeam === '' || player.team_id === selectedTeam;
      const matchGame = selectedGame === '' || player.game === selectedGame;
      const matchName = searchName === '' || 
        player.playId.toLowerCase().includes(searchName.toLowerCase()) || 
        player.name.toLowerCase().includes(searchName.toLowerCase());  // player.name도 검색에 포함
      
      let matchKeywords = false;
      if (player.keywords) {
        try {
          let keywordsArray = player.keywords;
        
          // tool.keywords가 string일 경우 JSON.parse()로 배열로 변환
          if (typeof keywordsArray === 'string') {
            
            try {
              // 첫 번째 파싱: 이중으로 JSON 문자열이 포함된 상태이므로 한 번 파싱
              keywordsArray = JSON.parse(keywordsArray);
              
              // 두 번째 파싱: 첫 번째 파싱이 성공적으로 이루어지면 다시 파싱
              if (typeof keywordsArray === 'string') {
                keywordsArray = JSON.parse(keywordsArray);
              }
              
            } catch (e) {
              console.error("Error parsing keywords string:", e);
              keywordsArray = [];  // 파싱 실패 시 빈 배열로 처리
            }
          }
        
          // keywordsArray가 배열이 맞는지 확인
          if (!Array.isArray(keywordsArray)) {
            console.error("keywordsArray is not an array. It is:", typeof keywordsArray);
            keywordsArray = [];  // 빈 배열로 처리
          }
        
          // 키워드 필터링
          matchKeywords = searchName === '' || keywordsArray.some(keyword => {
            const match = keyword.toLowerCase().includes(searchName.toLowerCase());
            return match;
          });
        
        } catch (error) {
          console.error("Error parsing keywords:", error);
        }
      }
      return matchTeam && matchGame && (matchName || matchKeywords);
    });
    setFilteredPlayers(newFilteredPlayers);
  };



  const handleEditPlayer = (id) => {
    navigate(`/dashboard/player/modify/${id}`);
  };

  const handleDeletePlayer = (id) => {
    deletePlayer(id).then(() => {
      loadPlayers();
    }).catch((error) => {
      console.error("Error deleting player:", error);
    });
  };

  return (
    <>
      <Layout />
      <div className='contents'>
        <div className='titleArea'>선수 관리</div>

        <div className='filters'>
          <div className='filter'>
            <select value={selectedGame} onChange={(e) => setSelectedGame(e.target.value)}>
              <option value=''>게임 선택</option>
              {games.map(game => (
                <option key={game.id} value={game.id}>{game.name}</option>
              ))}
            </select>
            <select value={selectedTeam} onChange={(e) => setSelectedTeam(e.target.value)}>
              <option value=''>팀 선택</option>
              {teams.map(team => (
                <option key={team.id} value={team.id}>{team.name}</option>
              ))}
            </select>
            <button onClick={handleFilter}>조회</button>
          </div>
          <div className='search'>
            <input
              type="text"
              placeholder="선수명, 이름"
              value={searchName}
              onChange={(e) => setSearchName(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleFilter(); // Enter 키를 누르면 조회 버튼과 동일한 동작 수행
                }
              }}
            />
            <button onClick={handleFilter}></button>
          </div>
        </div>

        <div className='util-wrap'>
          <div className='totalCnt'>Total {filteredPlayers.length}</div>
          <div className='button-wrap'><Link to="/dashboard/player/write">선수 등록</Link></div>
        </div>

        <div className='table-wrap'>
          <div className='table-head'>
            <div className='idx'>No</div>
            <div className='game'>게임</div> 
            <div className='team'>팀</div> 
            <div className='player'>선수</div>
            <div className='controll'>관리</div>
          </div>
          <PlayerList players={filteredPlayers} onEdit={handleEditPlayer} onDelete={handleDeletePlayer} />
        </div>
      </div>
    </>
  );
};

export default App;