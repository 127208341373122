import React, { forwardRef, useImperativeHandle, useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { fetchTools, fetchToolCates } from '../../api';
const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const ToolModal = forwardRef(({ show, onClose, onAddTool, onSave, selectedTools }, ref) => {
  const [tools, setTools] = useState([]);
  const [toolCatesMap, setToolCatesMap] = useState({});
  const [searchName, setSearchName] = useState('');
  const [filteredTools, setFilteredTools] = useState([]);
  const [addToolList, setAddToolList] = useState([]);

  useImperativeHandle(ref, () => ({
    triggerSave: () => {
      handleSave();
    }
  }));

  const handleSave = () => {
    onAddTool(addToolList);
    onClose();
  };

  useEffect(() => {
    fetchTools().then(response => {
      setTools(response.data);
    }).catch(error => {
      console.error('There was an error fetching the tools!', error);
    });

    fetchToolCates().then(response => {
      const toolCatesMap = response.data.reduce((acc, toolCate) => {
        acc[toolCate.id] = toolCate.name;
        return acc;
      }, {});
      setToolCatesMap(toolCatesMap);
    }).catch(error => {
      console.error('There was an error fetching the tool categories!', error);
    });
  }, []);

  useEffect(() => {
    setFilteredTools(
      tools.filter(tool =>
        tool.name.toLowerCase().includes(searchName.toLowerCase())
      )
    );
  }, [searchName, tools]);

  useEffect(() => {
    const initialTools = tools.filter(tool => selectedTools.includes(tool.id));
    setAddToolList(initialTools);
  }, [selectedTools, tools]);

  const handleAddTool = (tool) => {
    if (!addToolList.find(t => t.id === tool.id)) {
      setAddToolList([...addToolList, tool]);
    }
  };

  const handleRemoveTool = (toolId) => {
    setAddToolList(addToolList.filter(tool => tool.id !== toolId));
  };

  const handleSearch = (e) => {
    setSearchName(e.target.value);
  };

  const selectedToolObjects = tools.filter(tool => addToolList.some(selectedTool => selectedTool.id === tool.id));

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>장비 추가</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group>
            <div className='filters'>
              <div className='search'>
                <Form.Control
                  type="text"
                  placeholder="장비 이름으로 검색"
                  value={searchName}
                  onChange={handleSearch}
                />
              </div>
            </div>
          </Form.Group>

          <div className="modal-table">
            <p className='table-title'>장비 목록</p>
            <div className="table-head">
              <div className="idx">No</div>
              <div className="thumbnail">썸네일</div>
              <div className="cate">카테고리</div>
              <div className="name">제품명</div>
              <div className="controll">관리</div>
            </div>
            <div className="table-body">
              {filteredTools.map((tool, index) => (
                <li key={tool.id}>
                  <div className="idx">{index + 1}</div>
                  <div className="thumbnail">
                    {tool.thumbnail ? (
                      <img src={`${SERVER_URL}${tool.thumbnail}`} />
                    ) : (
                      <img src={`${SERVER_URL}/uploads/no-image.svg`} />
                    )}
                  </div>
                  <div className="cate">{toolCatesMap[tool.cate2]}</div>
                  <div className="name">{tool.name}</div>
                  <div className="controll">
                    <Button onClick={() => handleAddTool(tool)}>추가</Button>
                  </div>
                </li>
              ))}
            </div>
          </div>

          <div className="modal-table">
            <p className='table-title'>선택된 장비</p>
            <div className="table-head">
              <div className="idx">No</div>
              <div className="thumbnail">썸네일</div>
              <div className="cate">카테고리</div>
              <div className="name">제품명</div>
              <div className="controll">관리</div>
            </div>
            <div className="table-body">
              {selectedToolObjects.map((tool, index) => (
                <li key={tool.id}>
                  <div className="idx">{index + 1}</div>
                  <div className="thumbnail">
                    <img src={`${SERVER_URL}${tool.thumbnail}`} alt={tool.name} />
                  </div>
                  <div className="cate">{toolCatesMap[tool.cate2]}</div>
                  <div className="name">{tool.name}</div>
                  <div className="controll">
                    <Button onClick={() => handleRemoveTool(tool.id)}>삭제</Button>
                  </div>
                </li>
              ))}
            </div>
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleSave} className='modalSubmit'>완료</Button>
      </Modal.Footer>
    </Modal>
  );
});

export default ToolModal;