import React, { useState, useEffect } from 'react';
import { fetchTeams, fetchGames, fetchUserData, fetchMembershipById, fetchFavoritePlayers, removeFavoritePlayer, fetchUserPosts, fetchUserComments, fetchBoards, checkUsernameDuplication, updateUsername } from '../../api';
import { jwtDecode } from 'jwt-decode';
import { Link } from 'react-router-dom';

import Flags from 'react-world-flags';
import LayoutClient from '../../components/layout/LayoutClient';
import LayoutFooter from '../../components/layout/LayoutFooter';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const MyPage = () => {
  const [user, setUser] = useState(null);
  const [grade, setGrade] = useState(null);
  const [favorites, setFavorites] = useState([]);
  const [posts, setPosts] = useState([]);
  const [comments, setComments] = useState([]);
  const [categories, setCategories] = useState({});
  const [activeTab, setActiveTab] = useState('favorites');
  const [error, setError] = useState(null);
  const [teamMap, setTeamMap] = useState({});
  const [gameMap, setGameMap] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [newUsername, setNewUsername] = useState('');
  const [usernameAvailable, setUsernameAvailable] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        // JWT 디코딩
        const decodedToken = jwtDecode(token);
        const userId = decodedToken.id;
        
        // 사용자 데이터 가져오기
        const userResponse = await fetchUserData(userId);
        const userData = userResponse.data;
        setUser(userData);

        // 즐겨찾기 데이터 가져오기
        const favoritesResponse = await fetchFavoritePlayers(userId, token);
        setFavorites(favoritesResponse.data);

        // 등급 데이터 가져오기
        const gradeResponse = await fetchMembershipById(userData[0].grade);
        setGrade(gradeResponse.data);

        // 사용자가 작성한 게시물 가져오기
        const postsResponse = await fetchUserPosts(userId, token);
        console.log(postsResponse)
        setPosts(postsResponse);

        // 사용자가 작성한 게시물 가져오기
        const commentsResponse = await fetchUserComments(userId, token);
        console.log(commentsResponse)
        setComments(commentsResponse);

        // 카테고리 데이터 가져오기
        const boardsResponse = await fetchBoards();
        const categoriesMap = {};
        boardsResponse.data.forEach(category => {
          categoriesMap[category.id] = category.name;
        });
        setCategories(categoriesMap);

        const teamResponse = await fetchTeams();
        const gameResponse = await fetchGames();

        // 팀 정보를 매핑합니다.
        const teamMap = teamResponse.data.reduce((acc, team) => {
          acc[team.id] = { name: team.name, icon: team.icon };
          return acc;
        }, {});
        setTeamMap(teamMap);

        // 게임 정보를 매핑합니다.
        const gameMap = gameResponse.data.reduce((acc, game) => {
          acc[game.id] = game.name;
          return acc;
        }, {});
        setGameMap(gameMap);

        } catch (err) {
          console.error('Error fetching data:', err);
          setError('Failed to fetch data.');
        }
      } else {
        setError('No token found.');
      }
    };

    fetchData();
  }, []);

  const handleRemoveFavorite = async (playerId) => {
    const token = localStorage.getItem('token');
    const userId = jwtDecode(token).id;

    try {
      await removeFavoritePlayer(userId, playerId, token);
      setFavorites(prev => prev.filter(player => player.id !== playerId));
    } catch (error) {
      console.error('Failed to remove favorite:', error);
      setError('Failed to remove favorite.');
    }
  };



  const formatDate = (dateStr) => {
    const postDate = new Date(dateStr);
    const now = new Date();
    const today = now.toDateString();
    const postDateString = postDate.toDateString();

    if (today === postDateString) {
      return postDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });
    } else {
      return postDate.toLocaleDateString();
    }
  };

  const getCategoryName = (categoryId) => {
    return categories[categoryId] || 'Unknown';
  };

  const renderFavoritesTab = () => (
    <div className='favorites'>
      {favorites.length === 0 ? (
        <p className='error'>아직 즐겨찾기한 플레이어가 없습니다.</p>
      ) : (
        <div className='favorite-list'>
          {favorites.map(player => (
            <div key={player.id}>
              <Link to={`/player/detail/${player.id}`}>
              <div className='thumb-wrap'>
                {console.log(player)}
                {player.thumbnail ? (
                  <img src={`${SERVER_URL}${player.thumbnail}`} alt='thumbnail' />
                ) : (
                  <img src={`${SERVER_URL}/uploads/no-image.svg`} alt='no thumbnail' />
                )}
              </div>
              <p className='playId'>{player.playId}</p>
              <div className='name'>
                {player.nationality && (
                  <Flags code={player.nationality} style={{ width: 16, height: 10, marginLeft: 8 }} />
                )}
                {player.name}
              </div>
              <div className='team'>
                {teamMap[player.team_id] ? (
                  <>
                    <img src={`${SERVER_URL}${teamMap[player.team_id].icon}`} alt='team icon' style={{ width: 24, height: 24, marginRight: 4 }} />
                    {teamMap[player.team_id].name}
                  </>
                ) : 'Unknown'}
              </div>
              </Link>
              <button onClick={() => handleRemoveFavorite(player.id)} className='disactive'>
                <img
                  src={`${process.env.PUBLIC_URL}/img/icon-heart-d.svg`}
                  alt='Unfollow'
                  className="favorite-icon"
                />팔로우 취소
              </button>
            </div>
          ))}
        </div>
      )}
    </div>
  );

  const renderPostsTab = () => (
    <div className='posts'>
      {posts.length === 0 ? (
        <p className='error'>아직 작성한 게시글이 없습니다.</p>
      ) : (
        <>
          <div className='board-table-head'>
            <li className='category'>카테고리</li>
            <li className='subject'>제목</li>
            <li className='writer'>글쓴이</li>
            <li className='date'>날짜</li>
            <li className='hit'>댓글</li>
            <li className='hit'>조회</li>
            <li className='recommand'>추천</li>
          </div>
          <div className='board-inner'>
            <ul>
              {posts.map(post => (
                <li key={post.id}>
                  <p className='category'>{getCategoryName(post.category_id)}</p>
                  <p className='subject'>
                    <Link to={`/community/post/${post.id}`}>{post.title}</Link>
                  </p>
                  <div>
                    <p className='writer'>{post.author || 'Unknown'}</p>
                    <p className='date'>{formatDate(post.created_at)}</p>
                    <p className='hit'><span>조회</span>{post.commentCount}</p>
                    <p className='hit'><span>조회</span>{post.views}</p>
                    <p className='recommand'><span>추천</span>{post.likes}</p>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </>
      )}
    </div>
  );

  const renderCommentTab = () => (
    <div className='posts'>
      {comments.length === 0 ? (
        <p className='error'>아직 작성한 댓글이 없습니다.</p>
      ) : (
        <>
          <div className='board-table-head'>
            <li className='comment'>댓글 내용</li>
            <li className='writer'>글쓴이</li>
            <li className='cont'>본문 보기</li>
          </div>
          <div className='board-inner'>
            <ul>
              {comments.map(comment => (
                <li key={comment.id}>
                    <p className='comment'>{comment.content}</p>
                    <p className='writer'>{comment.author || 'Unknown'}</p>
                    <p className='cont'><Link to={`/community/post/${comment.post_id}`}>본문보기</Link></p>
                </li>
              ))}
            </ul>
          </div>
        </>
      )}
    </div>
  );


  const handleEditClick = () => {
    setIsEditing(true);
    setNewUsername(user[0].username); // 초기값으로 현재 닉네임 설정
  };

  const handleUsernameChange = (e) => {
    setNewUsername(e.target.value);
  };

  const handleUpdateUsername = async () => {
    try {
      // 닉네임 중복 확인
      const response = await checkUsernameDuplication(newUsername);
      if (response.data.isDuplicate) {
        alert('중복된 닉네임입니다.');
        setUsernameAvailable(false);
      } else {
        // 사용 가능한 닉네임일 경우
        if (window.confirm('사용 가능한 닉네임입니다. 사용하시겠습니까?')) {
          // 닉네임 업데이트
          await updateUsername(user[0].id, newUsername);
          setUser(prev => [{ ...prev[0], username: newUsername }]);
          setIsEditing(false);
        }
      }
    } catch (error) {
      console.error('Error updating username:', error);
    }
  };

  if (error) return <p>{error}</p>;

  const iconSrc = grade && grade[0].icon
    ? `${SERVER_URL}${grade[0].icon}` // 서버 URL과 아이콘 경로를 결합
    : `${SERVER_URL}/img/no-image.svg`; // 기본 이미지 경로

  return (
    <>
      <LayoutClient />
        <div className='client-contents'>
          <div className='inner'>
            <div className='path'>Home / 마이페이지</div>
                {user && (
                    <>
                        <div className="mypage-wrap">
                            <div className='thumb-wrap'>
                                <img src={iconSrc} alt="Grade icon" />
                            </div>
                            <div className="user-wrap">
                              <div className='field'>
                                <p className='label'>닉네임</p>
                                <div className='input-wrap'>
                                  {isEditing ? (
                                    <input
                                      type="text"
                                      value={newUsername}
                                      onChange={handleUsernameChange}
                                    />
                                  ) : (
                                    <p className='data'>{user[0].username}</p>
                                  )}
                                  {isEditing ? (
                                    <button onClick={handleUpdateUsername}>수정완료</button>
                                  ) : (
                                    <button onClick={handleEditClick}>수정</button>
                                  )}
                                </div>
                              </div>
                              <div className='field'><p className='label'>등급</p> <p className='data'>{grade ? grade[0].name : 'Loading...'}</p></div>
                            </div>
                        </div>
                        <div className='mycontents-wrap'>
                          <div className='tabs'>
                            <button className={activeTab === 'favorites' ? 'active' : ''} onClick={() => setActiveTab('favorites')}>
                              즐겨찾기한 플레이어
                            </button>
                            <button className={activeTab === 'posts' ? 'active' : ''} onClick={() => setActiveTab('posts')}>
                              내가 쓴 게시물
                            </button>
                            <button className={activeTab === 'comment' ? 'active' : ''} onClick={() => setActiveTab('comment')}>
                              내가 쓴 댓글
                            </button>
                          </div>
                          <div className='tab-content'>
                            {activeTab === 'favorites' && renderFavoritesTab()}
                            {activeTab === 'posts' && renderPostsTab()}
                            {activeTab === 'comment' && renderCommentTab()}
                          </div>
                        </div>
                    </>
                )}
            </div>
          </div>
      <LayoutFooter />
    </>
  );
};

export default MyPage;