import React, { useState, useEffect } from 'react';
import { fetchBoards, fetchPostById, updatePost } from '../../api';  // updatePost 추가
import { useNavigate, useParams } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import LayoutClient from '../../components/layout/LayoutClient';
import LayoutFooter from '../../components/layout/LayoutFooter';
import Quill from 'quill';
import ImageUploader from 'quill-image-uploader';

// Quill 모듈 등록
Quill.register('modules/imageUploader', ImageUploader);

// 이미지 업로드 최대 용량 (바이트 단위)
const MAX_IMAGE_SIZE = 2 * 1024 * 1024; // 2MB

// Quill 모듈 설정
const modules = {
  toolbar: [
    [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
    [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
    [{ size: [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ 'list': 'ordered' }, { 'list': 'bullet' },
     { 'indent': '-1' }, { 'indent': '+1' }],
    ['link', 'image', 'video'],
  ],
  clipboard: {
    matchVisual: false,
  },
  imageUploader: {
    upload: file => {
      return new Promise((resolve, reject) => {
        if (file.size > MAX_IMAGE_SIZE) {
          alert('2MB 이하의 파일을 업로드해주세요.');
          reject('Image size exceeds the maximum limit of 2MB');
          return;
        }

        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          resolve(reader.result);
        };
        reader.onerror = error => {
          reject('Image upload failed');
        };
      });
    }
  }
};

const Edit = () => {
  const navigate = useNavigate();
  const { id } = useParams();  // 게시글 ID 파라미터
  const [categories, setCategories] = useState([]);
  const [formData, setFormData] = useState({
    title: '',
    content: '',
    category_id: ''
  });
  const [error, setError] = useState(null);

  useEffect(() => {
    loadCategories();
    loadPostData();
  }, [id]);

  const loadCategories = async () => {
    try {
      const response = await fetchBoards();
      setCategories(response.data);
    } catch (error) {
      console.error("Error loading categories:", error);
    }
  };

  const loadPostData = async () => {
    try {
      const response = await fetchPostById(id);  // 게시글 데이터를 불러옴
      const post = response.data[0];
      console.log(post);
      setFormData({
        title: post.title,
        content: post.content,
        category_id: post.category_id,
      });
    } catch (error) {
      console.error("Error loading post data:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleContentChange = (content) => {
    setFormData({ ...formData, content });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('token');

    if (!token) {
      setError('No token found.');
      return;
    }

    if (formData.category_id === 'null') {
      alert('게시판 분류를 선택해주세요.');
      return;
    }

    try {
      const decodedToken = jwtDecode(token);
      const userId = decodedToken.id;

      await updatePost(id, formData, userId, token);  // 수정된 게시글 데이터 전송
      navigate(`/community/post/${id}`);
    } catch (error) {
      console.error("Error updating post:", error);
    }
  };

  return (
    <div>
      <LayoutClient />
      <div className='client-contents'>
        <div className='inner'>
          <div className='title-wrap'>
            <p className='title'>게시글 수정</p>
          </div>
          <div className='write-form'>
            <form onSubmit={handleSubmit}>
              <div className='write-group'>
                <select name="category_id" value={formData.category_id} onChange={handleChange}  className="displaynone">
                  <option value="">카테고리를 선택해주세요</option>
                  {categories.map((category) => (
                    <option key={category.id} value={category.id}>{category.name}</option>
                  ))}
                </select>
              </div>
              <div className='write-group'>
                <label>제목</label>
                <input type="text" name="title" value={formData.title} onChange={handleChange} />
              </div>
              <div className='write-group'>
                <label>내용</label>
                <ReactQuill
                  value={formData.content}
                  onChange={handleContentChange}
                  modules={modules}
                />
              </div>
              <button type="submit">수정</button>
            </form>
          </div>
        </div>
      </div>
      <LayoutFooter />
    </div>
  );
};

export default Edit;