import React, { useState } from 'react';

const MembershipList = ({ memberships, onEdit, onDelete }) => {
  const [selectedMembershipId, setSelectedMembershipId] = useState(null);

  const handleEditClick = (membershipId) => {
    setSelectedMembershipId(membershipId);
  };

  return (
    <ul>
      {memberships.map((membership) => (
        <li key={membership.id}>
          <button
            onClick={() => {
              onEdit(membership.id);
              handleEditClick(membership.id);
            }}
            className={selectedMembershipId === membership.id ? 'selected' : ''}
          >
            {membership.name}
          </button>
          <button onClick={() => onDelete(membership.id)} className='displaynone'>삭제</button>
        </li>
      ))}
    </ul>
  );
};

export default MembershipList;