import React, { useState, useEffect } from 'react';
import { fetchTools } from '../../api';

import Layout from '../../components/layout/Layout';
import ToolForm from '../../components/tool/ToolForm';

const App = () => {
  const [tools, setTools] = useState([]);
  const [currentToolId, setCurrentToolId] = useState(null);

  const handleSaveTool = () => {
    fetchTools().then((response) => {
      setTools(response.data);
    }).catch(error => {
      console.error('There was an error fetching the tool!', error);
    });
    setCurrentToolId(null);
  };


  return (
    <>
      <Layout />
      <div className='contents'>
        <div className='titleArea'>장비 관리</div>
        <div className='single-form-wrap'>
          <ToolForm onSave={handleSaveTool} />
        </div>
      </div>
    </>
  );
};

export default App;