import React, { useState, useEffect } from 'react';
import { fetchTools, fetchToolCates, fetchToolsByCategoryID } from '../../api';
import { useNavigate, Link } from 'react-router-dom';

import LayoutClient from '../../components/layout/LayoutClient';
import LayoutFooter from '../../components/layout/LayoutFooter';
import ToolList from './CompareList';

const App = () => {
  const [tools, setTools] = useState([]);
  const [toolCates, setToolCates] = useState([]);
  const [groupedCates, setGroupedCates] = useState([]);
  const [selectedCate2, setSelectedCate2] = useState(null); 
  const navigate = useNavigate();

  useEffect(() => {
    loadToolCates();
  }, []);

  const loadToolCates = () => {
    fetchToolCates()
      .then((response) => {
        const categories = response.data;

        // 대분류와 중분류를 그룹화
        const grouped = categories.reduce((acc, cate) => {
          if (cate.parent_id === null || cate.parent_id === "null") {
            // 대분류인 경우
            acc[cate.id] = { parent: cate, children: [] };
          } else {
            // 중분류인 경우
            if (acc[cate.parent_id]) {
              acc[cate.parent_id].children.push(cate);
            }
          }
          return acc;
        }, {});

        setToolCates(categories);

        // 대분류를 order 순으로 정렬
        const sortedGrouped = Object.values(grouped).sort((a, b) => a.parent.order - b.parent.order);

        setGroupedCates(sortedGrouped);

        // 첫 번째 중분류를 자동 선택
        const firstChild = categories.find((cate) => cate.parent_id !== null && cate.parent_id !== "null");
        if (firstChild) {
          setSelectedCate2(firstChild.id);
          loadToolsByCategory(firstChild.id);
        }
      })
      .catch((error) => {
        console.error("Error loading tool categories:", error);
      });
  };

  const loadToolsByCategory = (cate2Id) => {
    fetchToolsByCategoryID(cate2Id).then(response => {
      setTools(response.data);
    }).catch(error => {
      console.error("Error loading tools by category:", error);
    });
  };

  const handleCategoryClick = (cate2Id) => {
    setSelectedCate2(cate2Id);
    loadToolsByCategory(cate2Id);
  };

  return (
    <>
      <LayoutClient />
      <div className='client-contents'>
        <div className='inner'>
          <div className='path'>Home / 장비비교</div>
          <div className='title-wrap'>
            <p className='title'>장비비교</p>
          </div>
          <div className="tool-category-list">
            <p className='title'>카테고리</p>
            {groupedCates.map(({ parent, children }) => (
              <div key={parent.id} className="category-group">
                <p className="category-parent">{parent.name}</p>
                <div className="category-children">
                  {children.map((child) => (
                    <button
                      key={child.id}
                      onClick={() => handleCategoryClick(child.id)}
                      className={selectedCate2 === child.id ? "active" : ""}
                    >
                      {child.name}
                    </button>
                  ))}
                </div>
              </div>
            ))}
          </div>
          {selectedCate2 && <ToolList tools={tools} />}
        </div>
      </div>
      <LayoutFooter />
    </>
  );
};

export default App;