import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { fetchToolSettingById, fetchToolSettings } from '../../api';

import Layout from '../../components/layout/Layout';
import ToolSettingForm from '../../components/toolSetting/ToolSettingForm';

const App = () => {
  const toolSettingId = useParams().id;
  const [toolSetting, setToolSetting] = useState(null);

  useEffect(() => {
    if (toolSettingId) {
      fetchToolSettingById(toolSettingId).then((response) => {
        console.log('Fetched toolSetting:', response.data); 
        setToolSetting(response.data);
      }).catch((error) => {
        console.error('There was an error fetching the toolSetting!', error);
      });
    }
  }, [toolSettingId]);

  const handleSaveToolSetting = () => {
    fetchToolSettings().then((response) => {
      setToolSetting(response.data);
    }).catch(error => {
      console.error('There was an error fetching the toolSetting!', error);
    });
  };

  return (
    <>
      <Layout />
      <div className='contents'>
        <div className='titleArea'>장비 세팅 관리</div>
        <div className='single-form-wrap'>
          <ToolSettingForm toolSettingId={toolSettingId} toolSetting={toolSetting} onSave={handleSaveToolSetting} />
        </div>
      </div>
    </>
  );
};

export default App;