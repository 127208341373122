import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { fetchPostById, incrementPostLikes, decrementPostLikes, postComment, fetchCommentsByPostId, toggleLikeStatus, checkUserLikeStatus, deletePost } from '../../api';
import { jwtDecode } from 'jwt-decode';
import PostDetailUI from './PostDetail';
import LayoutClient from '../../components/layout/LayoutClient';
import LayoutFooter from '../../components/layout/LayoutFooter';

const PostDetail = () => {
  const { id } = useParams();  // URL에서 게시글 ID를 가져옴
  const navigate = useNavigate();
  const [post, setPost] = useState([]);
  const [isLiked, setIsLiked] = useState(false);
  const [comments, setComments] = useState([]);
  const [currentUserId, setCurrentUserId] = useState(null);

  useEffect(() => {
    const fetchPostAndComments = async () => {
        try {
            const postResponse = await fetchPostById(id);
            setPost(postResponse.data);
            const commentsResponse = await fetchCommentsByPostId(id);
            setComments(commentsResponse.data);

            const token = localStorage.getItem('token');
            if (token) {
              const decodedToken = jwtDecode(token);
              const userId = decodedToken.id;
              setCurrentUserId(decodedToken.id);
              
              // 서버에서 좋아요 상태를 확인
              const likeStatusResponse = await checkUserLikeStatus(id, userId);
              setIsLiked(likeStatusResponse.data.isLiked);
            }
        } catch (error) {
            console.error('Error fetching post or comments:', error);
        }
    };

    fetchPostAndComments();
  }, [id]);

  const handleLikeToggle = async () => {
    try {
        const token = localStorage.getItem('token');
        if (!token) return;  // 로그인 상태 확인
        const decodedToken = jwtDecode(token);
        const userId = decodedToken.id;
    
        // 서버에 좋아요 상태를 토글
        await toggleLikeStatus(id, userId);
    
        // 로컬 상태 업데이트
        setIsLiked(prevState => {
          const newIsLiked = !prevState;  // 현재 상태의 반대로 설정
          setPost(prevPost => {
            const updatedPost = { ...prevPost[0], likes: newIsLiked ? prevPost[0].likes + 1 : prevPost[0].likes - 1 };
            return [updatedPost];
          });
          return newIsLiked;  // 상태 업데이트
        });
    
      } catch (error) {
        console.error('Error toggling like status:', error);
      }
  };

  const handleAddComment = async (content, parentId = null) => {
    const token = localStorage.getItem('token');
    try {
      const decodedToken = jwtDecode(token);
      const userId = decodedToken.id;
      await postComment(id, userId, content, parentId);
      const commentsResponse = await fetchCommentsByPostId(id);
      setComments(commentsResponse.data);
    } catch (error) {
      console.error('Error adding comment:', error);
    }
  };

  const calculateTotalComments = (comments) => {
    let total = 0;
    comments.forEach(comment => {
      total += 1;
      if (comment.replies && comment.replies.length > 0) {
        total += calculateTotalComments(comment.replies);
      }
    });
    return total;
  };

  const totalComments = calculateTotalComments(comments);

  // 게시글 삭제 함수
  const handleDeletePost = async () => {
    try {
      await deletePost(id);  // deletePost API 호출
      navigate(-1);  // 게시판 페이지로 리디렉션
    } catch (error) {
      console.error('Error deleting post:', error);
    }
  };

  return (
    <>
    <LayoutClient />
        <PostDetailUI
        post={post}
        totalComments={totalComments}
        isLiked={isLiked}
        handleLikeToggle={handleLikeToggle}
        handleAddComment={handleAddComment}
        comments={comments}
        currentUserId={currentUserId}
        handleDeletePost={handleDeletePost}
        />
    <LayoutFooter />
    </>
  );
};

export default PostDetail;


