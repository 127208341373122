import React, { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { jwtDecode } from 'jwt-decode';

const NaverCallback = () => {
  const { login: loginContext } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const token = queryParams.get('token');
    
    if (token) {
      const decodedToken = jwtDecode(token);
      console.log(decodedToken); // JWT 디코딩 결과 확인
      localStorage.setItem('authToken', token);
      loginContext(token); // 인증 상태 업데이트
      navigate('/');
    } else {
      alert('네이버 인증이 실패했습니다.');
      navigate('/login');
    }
  }, [loginContext, navigate]);

  return <div>네이버 로그인 중...</div>;
};

export default NaverCallback;