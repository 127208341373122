import React, { useState, useEffect } from 'react';
import { fetchGameById } from '../../api';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;
const defaultImage = `${SERVER_URL}/uploads/no-image.svg`;  // 기본 이미지를 가져옵니다.

const GameForm = ({ gameId, formData, setFormData, onDelete, onSaveAllSettings }) => {
  const [previewIcon, setPreviewIcon] = useState(null); 
  const [previewBanner, setPreviewBanner] = useState(null);
  
  const chkId = gameId;

  const handleImageError = (e) => {
    e.target.src = defaultImage;
  }

  useEffect(() => {
    if (gameId) {
      fetchGameById(gameId).then((response) => {
        const game = response.data.find((s) => s.id === gameId);
        setFormData(game);
        if (game.icon) {
          setPreviewIcon(`${SERVER_URL}${game.icon}`);
        } else {
          setPreviewIcon(`${SERVER_URL}/uploads/no-image.svg`);
        }
        if (game.banner) {
          setPreviewBanner(`${SERVER_URL}${game.banner}`);
        } else {
          setPreviewBanner(`${SERVER_URL}/uploads/no-image.svg`);
        }
      });
    }
  }, [gameId, setFormData]);

  const handleIconChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData({ ...formData, icon: file }); // formData에 아이콘 파일 설정
        setPreviewIcon(reader.result); // 아이콘 미리보기 설정
      };
      reader.readAsDataURL(file);
    }
  };

  const handleBannerChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData({ ...formData, banner: file }); // formData에 썸네일 파일 설정
        setPreviewBanner(reader.result); // 썸네일 미리보기 설정
      };
      reader.readAsDataURL(file);
    }
  };
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <>
      <form className="game-info">
        <div className="title-wrap">
          {chkId ? (
            <><p className="title"><img src="/img/icon-folder-active.png" />{formData.name}</p><div className='button-wrap'><button type="button" onClick={onSaveAllSettings}>수정완료</button><button onClick={() => onDelete(gameId)} className='delete'>삭제</button></div></>
          ) : (
            <><p className="title"><img src="/img/icon-folder-active.png" />새 게임</p><div className='button-wrap'><button type="button" onClick={onSaveAllSettings}>저장</button></div></>
          )}
        </div>
        <div className="sub-title">기본 설정</div>

        <div className="input-wrap">
          <p className="label">분류명</p>
          <input type="text" name="name" value={formData.name} onChange={handleChange} placeholder="게임 이름" />
        </div>

        <div className="input-wrap">
          <p className="label">메인 진열 여부</p>
          <label>
            <input type="radio" name="status" value="진열함" checked={formData.status === '진열함'} onChange={handleChange} />
            진열함
          </label>
          <label>
            <input type="radio" name="status" value="진열안함" checked={formData.status === '진열안함'} onChange={handleChange} />
            진열안함
          </label>
        </div>

        <div className="input-wrap">
          <p className="label">노출 여부</p>
          <label>
            <input type="radio" name="exposure" value="노출함" checked={formData.exposure === '노출함'} onChange={handleChange} />
            노출함
          </label>
          <label>
            <input type="radio" name="exposure" value="노출안함" checked={formData.exposure === '노출안함'} onChange={handleChange} />
            노출안함
          </label>
        </div>

        <div className="input-wrap">
          <p className="label">아이콘</p>
          {previewIcon && (<img src={previewIcon} className="iconPreview" alt="icon preview" onError={handleImageError}  />)}
          <label className="file-button">
            <input type="file" name="icon" onChange={handleIconChange} className='displaynone' accept="image/*" />
            {previewIcon ? '변경' : '추가'}
          </label>
        </div>
        

        <div className="input-wrap">
          <p className="label">썸네일</p>
          {previewBanner && (<img src={previewBanner} className="bannerPreview" alt="banner preview" onError={handleImageError}  />)}
          <label className="file-button">
            <input type="file" name="banner" onChange={handleBannerChange} className='displaynone' accept="image/*" />
            {previewIcon ? '변경' : '추가'}
          </label>
        </div>

        <div className="input-wrap">
          <p className="label">분류 설명 </p>
          <textarea name="description" value={formData.description} onChange={handleChange} placeholder="설명"></textarea>
        </div>
      </form>
    </>
  );
};

export default GameForm;