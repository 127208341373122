import React, { useState, useEffect } from 'react';
import CommentItem from './CommentItem';
import { deleteComment } from '../../api';
const CommentList = ({ comments, onAddReply, userId }) => {
  const [commentList, setCommentList] = useState(comments);

  useEffect(() => {
    // props로 전달받은 comments가 바뀔 때마다 상태 업데이트
    setCommentList(comments);
  }, [comments]);

  // 댓글 삭제 처리
  const handleDeleteComment = async (commentId, parentId = null) => {
    try {
      await deleteComment(commentId);  // 댓글 삭제 API 호출

      // 대댓글인 경우, 부모 댓글에서 대댓글 목록에서 해당 대댓글을 삭제
      if (parentId) {
        setCommentList(prevComments =>
          prevComments.map(comment => 
            comment.id === parentId 
              ? { ...comment, replies: comment.replies.filter(reply => reply.id !== commentId) }  // 대댓글 목록 업데이트
              : comment
          )
        );
      } else {
        // 댓글인 경우 전체 댓글 목록에서 해당 댓글을 삭제
        setCommentList(prevComments => prevComments.filter(comment => comment.id !== commentId));
      }
    } catch (error) {
      console.error('댓글 삭제 실패:', error);
    }
  };

  return (
    <ul className='comment-list'>
      {commentList.map(comment => (
        <CommentItem
          key={comment.id}
          comment={comment}
          onAddReply={onAddReply}
          chkUser={userId}
          onDelete={handleDeleteComment}
        />
      ))}
    </ul>
  );
};

export default CommentList;