import React, { useState, useEffect } from 'react';
import { fetchToolSettings, deleteToolSetting } from '../../api';
import { useNavigate, Link } from 'react-router-dom';

import Layout from '../../components/layout/Layout';
import ToolSettingList from '../../components/toolSetting/ToolSettingList';

const App = () => {
  const [toolSettings, setToolSettings] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    loadToolSettings();
  }, []);

  const loadToolSettings = () => {
    fetchToolSettings().then((response) => {
      setToolSettings(response.data);
    }).catch((error) => {
      console.error("Error loading toolSettings:", error);
    });
  };
  
  const handleEditToolSetting = (id) => {
    navigate(`/dashboard/toolSetting/modify/${id}`);
  };

  const handledeleteToolSetting = (id) => {
    deleteToolSetting(id).then(() => {
      loadToolSettings();
    }).catch((error) => {
      console.error("Error deleting toolSetting:", error);
    });
  };

  return (
    <>
      <Layout />
      <div className='contents'>
        <div className='titleArea'>장비 세팅 관리</div>

        <div className='util-wrap'>
          <div className='totalCnt'>Total {toolSettings.length}</div>
          <div className='button-wrap'><Link to="/dashboard/toolSetting/write">세팅 등록</Link></div>
        </div>

        <div className='table-wrap'>
          <div className='table-head'>
            <div className='idx'>No</div>
            <div className='settingName'>세팅명</div>
            <div className='controll'>관리</div>
          </div>
          <ToolSettingList toolSettings={toolSettings} onEdit={handleEditToolSetting} onDelete={handledeleteToolSetting} />
        </div>
      </div>
    </>
  );
};

export default App;