import React, { useState } from 'react';

const ToolSettingList = ({ toolSettings, onEdit, onDelete }) => {

  const [currentPage, setCurrentPage] = useState(1);
  const postsPerPage = 20;

  const indexOfLastTool = currentPage * postsPerPage;
  const indexOfFirstTool = indexOfLastTool - postsPerPage;
  const currentPosts = toolSettings.slice(indexOfFirstTool, indexOfLastTool);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(toolSettings.length / postsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <>
      <div className='table-body'>
        <ul>
          {currentPosts.map((toolSetting, index) => (
            <li key={toolSetting.id}>
              <div className='idx'>{index + 1}</div>
              <div className='settingName' onClick={() => onEdit(toolSetting.id)}>
                {toolSetting.name}
              </div>
              <div className='controll'>
                <button onClick={() => onEdit(toolSetting.id)}>수정</button>
                <button onClick={() => onDelete(toolSetting.id)} className='delete'>삭제</button>
              </div>
            </li>
          ))}
        </ul>
      </div>

      {/* 페이지네이션 버튼 */}
      <div className='pagination'>
        {pageNumbers.map(number => (
          <button key={number} onClick={() => paginate(number)} className={number === currentPage ? 'active' : ''}>
            {number}
          </button>
        ))}
      </div>

    </>
  );
};

export default ToolSettingList;