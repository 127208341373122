import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { fetchGames } from '../api';
import LayoutClient from '../components/layout/LayoutClient';
import LayoutFooter from '../components/layout/LayoutFooter';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const Main = () => {
  const [games, setGames] = useState([]);

  useEffect(() => {
    loadGames();
  }, []);

  const loadGames = () => {
    fetchGames().then((response) => {
      setGames(response.data);
    }).catch((error) => {
      console.error("Error loading games:", error);
    });
  };

  return (
    <>
      <LayoutClient />
        <div className='main'>
            <div className='search-wrap'>
                <p className='title'>JANGBIBUG.</p>
            </div>
        </div>
        <div className='client-contents'>
          <div className='inner'>
            <div className='title-wrap'>
                <p className='title'>Game Lists</p>
                <Link to='/game'>전체 보기</Link>
            </div>
            <div className='game-wrap'>
              {games.filter(
                (game) => game.status == '진열함'
              ).map((game) => (
              <Link to={`/game/detail/${game.id}`} key={game.id} className='game'>
                <div className='thumb-wrap'><img src={`${SERVER_URL}${game.banner}`} alt={`${game.name} banner`} /></div>
                <p className='name'>{game.name}</p>
              </Link>
              ))}
            </div>
          </div>
        </div>
      <LayoutFooter />
    </>

  );
};

export default Main;