import React, { useState, useEffect } from 'react';
import { createMembership, updateMembership, deleteMembership, fetchMembershipById } from '../../api';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const MembershipForm = ({ membershipId, onSave, onDelete }) => {
  const [previewIcon, setPreviewIcon] = useState(null); 
  const [formData, setFormData] = useState({
    name: '',
    icon: null,
    is_default: false
  });

  const chkId = membershipId;

  useEffect(() => {
    if (membershipId) {
      fetchMembershipById(membershipId).then((response) => {
        const membership = response.data.find((s) => s.id === membershipId);
        setFormData({
          name: membership.name || '',
          icon: null,  // 초기화
          is_default: membership.is_default === "1",  // "1"이면 true, 아니면 false
        });
        if (membership.icon && membership.icon != 'null' ) {
          setPreviewIcon(`${SERVER_URL}${membership.icon}`);
        } else if ( membership.icon == 'null' ) {
          setPreviewIcon(`${SERVER_URL}/uploads/no-image.svg`);
        } else {
          setPreviewIcon(`${SERVER_URL}/uploads/no-image.svg`);
        }
      });
    }

  }, [membershipId]);

  const handleIconChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData({ ...formData, icon: file }); // formData에 아이콘 파일 설정
        setPreviewIcon(reader.result); // 아이콘 미리보기 설정
      };
      reader.readAsDataURL(file);
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({ ...formData, [name]: type === 'checkbox' ? checked : value });
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    const formDataToSend = new FormData();
    formDataToSend.append('name', formData.name);
    formDataToSend.append('icon', formData.icon);
    formDataToSend.append('is_default', formData.is_default);
    if (membershipId) {
      updateMembership(membershipId, formDataToSend).then(onSave);
    } else {
      createMembership(formDataToSend).then(onSave);
    }
    window.location.reload();
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="title-wrap">
            {chkId ? (
                <><p className="title"><img src="/img/icon-folder-active.png" />{formData.name}</p><div className='button-wrap'><button type="submit">수정 완료</button><button onClick={() => onDelete(membershipId)} className='delete'>삭제</button></div></>
              ) : (
                <><p className="title"><img src="/img/icon-folder-active.png" />새 회원 등급</p><div className='button-wrap'><button type="submit">저장</button></div></>
              )}
        </div>
        <div className="sub-title">기본 설정</div>
        <div className="input-wrap">
          <p className="label">등급명</p>
          <input type="text" name="name" value={formData.name} onChange={handleChange} placeholder="등급 이름" />
        </div>
        <div className="input-wrap">
          <p className="label">아이콘</p>
          {previewIcon && (<img src={previewIcon} className="iconPreview" alt="icon preview" />)}
          <label className="file-button">
            <input type="file" name="icon" onChange={handleIconChange} className='displaynone' accept="image/*" />
            {previewIcon ? '변경' : '추가'}
          </label>
        </div>
        <div className="input-wrap">
          <p className="label">기본 등급으로 설정</p>
          <label>
            <input
              type="checkbox"
              name="is_default"
              checked={formData.is_default}
              onChange={handleChange}
            />
            설정함
          </label>
        </div>
      </form>
    </>
    
  );
};

export default MembershipForm;