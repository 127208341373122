import React, { useState, useEffect } from 'react';
import { fetchToolCates, deleteToolCate } from '../../api';
import { Link } from 'react-router-dom';

import Layout from '../../components/layout/Layout';
import ToolCateForm from '../../components/categoryToolCate/ToolCateForm';
import ToolCateList from '../../components/categoryToolCate/ToolCateList';

const App = () => {
  const [toolCates, setToolCates] = useState([]);
  const [currentToolCateId, setCurrentToolCateId] = useState(null);
  const [appendParentId, setAppendParentId] = useState(null);
  const [isOrdering, setIsOrdering] = useState(false);

  useEffect(() => {
    loadToolCates();
  }, []);

  useEffect(() => {
    if (currentToolCateId !== null) {
      setAppendParentId(null);
    }
  }, [currentToolCateId]);

  const loadToolCates = () => {
    fetchToolCates().then((response) => {
      setToolCates(response.data);
    }).catch((error) => {
      console.error("Error loading toolcates:", error);
    });
  };

  const handleEditToolCate = (id) => {
    setCurrentToolCateId(id);
  };

  const handleAppendToolCate = (id) => {
    setCurrentToolCateId(id);
    setAppendParentId(id);
  };

  const handleSaveToolCate = () => {
    loadToolCates();
    setCurrentToolCateId(null);
    setAppendParentId(null);
  };

  const handleDeleteToolCate = (id) => {
    deleteToolCate(id).then(() => {
      loadToolCates();
      if (id === currentToolCateId) {
        setCurrentToolCateId(null);
      }
    }).catch((error) => {
      console.error("Error deleting toolcate:", error);
    });
  };

  const handleUpdateToolCates = (updatedToolCates) => {
    setToolCates(updatedToolCates);
  };

  const handleOrderChangeToggle = () => {
    setIsOrdering(!isOrdering);
  };


  return (
    <>
      <Layout />
      <div className='contents'>
        <div className='titleArea'>분류 관리</div>
        <div className='subCate'>
          <Link to="/dashboard/category/game">게임 분류</Link>
          <Link to="/dashboard/category/team">팀 분류</Link>
          <Link to="/dashboard/category/toolCate" className='selected'>장비 분류</Link>
        </div>

        <div className='list-form'>
          <div className={`list-wrap ${isOrdering ? 'ordering' : ''}`}>
            <div className='title-wrap'>
              <p>장비 분류</p>
              <div className='controll-wrap'>
                <button onClick={handleOrderChangeToggle} className={` ${isOrdering ? 'ordering' : ''}`}>
                  {isOrdering ? '변경완료' : '순서변경'}
                </button>
                <a href="/dashboard/category/toolCate">분류 추가</a>
              </div>
            </div>
            <ToolCateList toolCates={toolCates} onEdit={handleEditToolCate} onAppendChild={handleAppendToolCate} onDelete={handleDeleteToolCate} setToolCates={handleUpdateToolCates} />
          </div>
          <div className='form-wrap'>
            {currentToolCateId ? (
              appendParentId ? (
                <ToolCateForm parentId={appendParentId} onSave={handleSaveToolCate} onDelete={handleDeleteToolCate} />
              ) : (
                <ToolCateForm toolCateId={currentToolCateId} onSave={handleSaveToolCate} onDelete={handleDeleteToolCate} />
              )
            ) : (
              <ToolCateForm onSave={handleSaveToolCate} />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default App;