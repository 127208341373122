import React, { useState } from 'react';
import { updateToolCateOrder } from '../../api';

const ToolCateList = ({ toolCates, onEdit, onDelete, onAppendChild, setToolCates }) => {
  const [selectedToolCateId, setSelectedToolCateId] = useState(null);

  const handleEditClick = (toolCateId) => {
    setSelectedToolCateId(toolCateId);
  };

  const handleMoveUp = (toolCateId) => {
    const updatedToolCates = [...toolCates];
    const currentToolCate = updatedToolCates.find(toolCate => toolCate.id === toolCateId);
    
    // 대분류인 경우
    if (currentToolCate.parent_id === null) {
      // 대분류끼리만 순서 변경
      const sortedToolCates = updatedToolCates.filter(toolCate => toolCate.parent_id === null)
        .sort((a, b) => a.order - b.order); // order에 따라 대분류만 정렬
      
      const currentIndex = sortedToolCates.findIndex(toolCate => toolCate.id === toolCateId);
      if (currentIndex > 0) {
        // 이전 항목과 순서 변경
        const prevToolCate = sortedToolCates[currentIndex - 1];
        const temp = currentToolCate.order;
        currentToolCate.order = prevToolCate.order;
        prevToolCate.order = temp;
  
        // 순서 변경된 항목을 DB에 반영
        updateToolCateOrder(currentToolCate.id, currentToolCate.order)
          .then(() => updateToolCateOrder(prevToolCate.id, prevToolCate.order))
          .then(() => {
            setToolCates(updatedToolCates); // 상태 반영
            console.log('Order updated successfully');
          })
          .catch((error) => {
            console.error('Error updating order:', error);
          });
      }
    } else {
      // 중분류인 경우 (같은 parent_id를 가진 항목들끼리만 순서 변경)
      const sameParentToolCates = updatedToolCates.filter(toolCate => toolCate.parent_id === currentToolCate.parent_id)
        .sort((a, b) => a.order - b.order); // parent_id가 같은 항목들만 정렬
  
      const currentIndex = sameParentToolCates.findIndex(toolCate => toolCate.id === toolCateId);
      if (currentIndex > 0) {
        // 이전 항목과 순서 변경
        const prevSameParentToolCate = sameParentToolCates[currentIndex - 1];
        const temp = currentToolCate.order;
        currentToolCate.order = prevSameParentToolCate.order;
        prevSameParentToolCate.order = temp;
  
        // 순서 변경된 항목을 DB에 반영
        updateToolCateOrder(currentToolCate.id, currentToolCate.order)
          .then(() => updateToolCateOrder(prevSameParentToolCate.id, prevSameParentToolCate.order))
          .then(() => {
            setToolCates(updatedToolCates); // 상태 반영
            console.log('Order updated successfully');
          })
          .catch((error) => {
            console.error('Error updating order:', error);
          });
      }
    }
  };
  
  const handleMoveDown = (toolCateId) => {
    const updatedToolCates = [...toolCates];
    const currentToolCate = updatedToolCates.find(toolCate => toolCate.id === toolCateId);
    
    // 대분류인 경우
    if (currentToolCate.parent_id === null) {
      // 대분류끼리만 순서 변경
      const sortedToolCates = updatedToolCates.filter(toolCate => toolCate.parent_id === null)
        .sort((a, b) => a.order - b.order); // order에 따라 대분류만 정렬
  
      const currentIndex = sortedToolCates.findIndex(toolCate => toolCate.id === toolCateId);
      if (currentIndex < sortedToolCates.length - 1) {
        // 다음 항목과 순서 변경
        const nextToolCate = sortedToolCates[currentIndex + 1];
        const temp = currentToolCate.order;
        currentToolCate.order = nextToolCate.order;
        nextToolCate.order = temp;
  
        // 순서 변경된 항목을 DB에 반영
        updateToolCateOrder(currentToolCate.id, currentToolCate.order)
          .then(() => updateToolCateOrder(nextToolCate.id, nextToolCate.order))
          .then(() => {
            setToolCates(updatedToolCates); // 상태 반영
            console.log('Order updated successfully');
          })
          .catch((error) => {
            console.error('Error updating order:', error);
          });
      }
    } else {
      // 중분류인 경우 (같은 parent_id를 가진 항목들끼리만 순서 변경)
      const sameParentToolCates = updatedToolCates.filter(toolCate => toolCate.parent_id === currentToolCate.parent_id)
        .sort((a, b) => a.order - b.order); // parent_id가 같은 항목들만 정렬
  
      const currentIndex = sameParentToolCates.findIndex(toolCate => toolCate.id === toolCateId);
      if (currentIndex < sameParentToolCates.length - 1) {
        // 다음 항목과 순서 변경
        const nextSameParentToolCate = sameParentToolCates[currentIndex + 1];
        const temp = currentToolCate.order;
        currentToolCate.order = nextSameParentToolCate.order;
        nextSameParentToolCate.order = temp;
  
        // 순서 변경된 항목을 DB에 반영
        updateToolCateOrder(currentToolCate.id, currentToolCate.order)
          .then(() => updateToolCateOrder(nextSameParentToolCate.id, nextSameParentToolCate.order))
          .then(() => {
            setToolCates(updatedToolCates); // 상태 반영
            console.log('Order updated successfully');
          })
          .catch((error) => {
            console.error('Error updating order:', error);
          });
      }
    }
  };

  // 상위 분류 및 하위 분류를 재귀적으로 렌더링하는 함수
  const renderToolCates = (parentId = 'null') => {
    // 부모 ID가 같은 항목들만 필터링하고, order 값을 기준으로 정렬
    const filteredToolCates = toolCates
      .filter(toolCate => parentId === null ? toolCate.parent_id === null : toolCate.parent_id == parentId)
      .sort((a, b) => a.order - b.order);

    return filteredToolCates.map(toolCate => (
      <div key={toolCate.id}>
        <li>
        <div className='button-wrap'>
          <button
            onClick={() => {
              onEdit(toolCate.id);
              handleEditClick(toolCate.id);
            }}
            className={selectedToolCateId === toolCate.id ? 'selected' : ''}
          >
            {toolCate.name}
          </button>
          <button 
            onClick={() => onAppendChild(toolCate.id)} 
            className={selectedToolCateId === toolCate.id ? 'active' : 'disactive'}
          >
            +
          </button>
          {/* 순서 변경 버튼 추가 */}
          <button onClick={() => handleMoveUp(toolCate.id)} className='arrow'>↑</button>
          <button onClick={() => handleMoveDown(toolCate.id)} className='arrow'>↓</button>
      </div>
        </li>
        {renderToolCates(toolCate.id).length > 0 && (
          <ul className='child-list'>
            {renderToolCates(toolCate.id)}
          </ul>
        )}
      </div>
    ));
  };
  
  return (
    <ul>
      {renderToolCates()}
    </ul>
  );
};

export default ToolCateList;