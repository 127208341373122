import React, { useState, useEffect } from 'react';
import { fetchTeams, fetchGames } from '../../api';
import Flags from 'react-world-flags';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;
const defaultImage = `${SERVER_URL}/uploads/no-image.svg`;  // 기본 이미지를 가져옵니다.

const PlayerList = ({ players, onEdit, onDelete }) => {
  const handleImageError = (e) => {
    e.target.src = defaultImage;
  }
  const [teamMap, setTeamMap] = useState({});
  const [gameMap, setGameMap] = useState({});

  useEffect(() => {
    // 팀 목록 가져오기
    fetchTeams().then(response => {
        // 팀 ID와 이름 매핑 객체 생성
        const teamMap = response.data.reduce((acc, team) => {
            acc[team.id] = team.name;
            return acc;
        }, {});
        setTeamMap(teamMap);
    }).catch(error => {
        console.error('There was an error fetching the teams!', error);
    });
  }, []);

  useEffect(() => {
      // 게임 목록 가져오기
      fetchGames().then(response => {
          // 게임 ID와 이름 매핑 객체 생성
          const gameMap = response.data.reduce((acc, game) => {
              acc[game.id] = game.name;
              return acc;
          }, {});
          setGameMap(gameMap);
      }).catch(error => {
          console.error('There was an error fetching the Games!', error);
      });
  }, []);

  const [currentPage, setCurrentPage] = useState(1);
  const postsPerPage = 20;

  const indexOfLastTool = currentPage * postsPerPage;
  const indexOfFirstTool = indexOfLastTool - postsPerPage;
  const currentPosts = players.slice(indexOfFirstTool, indexOfLastTool);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(players.length / postsPerPage); i++) {
    pageNumbers.push(i);
  }


  return (
    <>
      <div className='table-body'>
        <ul>
          {currentPosts.map((player, index) => (
            <li key={player.id}>
              <div className='idx'>{index + 1}</div>
              <div className='game'>{gameMap[player.game] || 'Unknown'}</div>
              <div className='team'>{teamMap[player.team_id] || 'Unknown'}</div>
              <div className='player' onClick={() => onEdit(player.id)}>
                {player.thumbnail ? (
                  <img src={`${SERVER_URL}${player.thumbnail}`} alt='thumbnail' onError={handleImageError}  />
                ) : (
                  <img src={`${SERVER_URL}/uploads/no-image.svg`} alt='no thumbnail' />
                )}
                {player.playId}
                {/*player.nationality && (
                  <>
                    <Flags code={player.nationality} style={{ width: 20, height: 15, marginLeft: 10 }} />
                    {player.nationality}
                  </>
                )*/}
              </div>
              <div className='controll'>
                <button onClick={() => onEdit(player.id)}>수정</button>
                <button onClick={() => onDelete(player.id)} className='delete'>삭제</button>
              </div>
            </li>
          ))}
        </ul>
      </div>

      {/* 페이지네이션 버튼 */}
      <div className='pagination'>
        {pageNumbers.map(number => (
          <button key={number} onClick={() => paginate(number)} className={number === currentPage ? 'active' : ''}>
            {number}
          </button>
        ))}
      </div>
    
    </>

  );
};

export default PlayerList;