import React, { useState, useEffect } from 'react';
import { signup, checkEmailDuplication, sendVerificationCode, verifyCode, checkUsernameDuplication  } from '../../api';
import { Link, useNavigate } from 'react-router-dom';
import LayoutClient from '../../components/layout/LayoutClient';
import LayoutFooter from '../../components/layout/LayoutFooter';
import Modal from './ConsentModal.js';

const Signup = () => {
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
    confirmPassword: '',
    join_date: ''
  });
  const [verificationCode, setVerificationCode] = useState('');
  const [emailSent, setEmailSent] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [countdown, setCountdown] = useState(0);
  const [usernameAvailable, setUsernameAvailable] = useState(null);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [privacyAccepted, setPrivacyAccepted] = useState(false);
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [showPrivacyModal, setShowPrivacyModal] = useState(false);

  const navigate = useNavigate();

  const [passwordConditions, setPasswordConditions] = useState({
    length: false,
    combination: false
  });

  useEffect(() => {
    let timer;
    if (countdown > 0 && emailSent) {
      timer = setInterval(() => {
        setCountdown(prev => prev - 1);
      }, 1000);
    } else if (countdown === 0 && emailSent) {
      setEmailSent(false); // 인증번호 유효시간 초과
    }
    return () => clearInterval(timer);
  }, [countdown, emailSent]);

  useEffect(() => {
    validatePassword(formData.password);
  }, [formData.password]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleEmailCheck = () => {
    alert('사용 가능한 이메일인지 확인 중입니다. 잠시만 기다려 주세요.');

    checkEmailDuplication(formData.email)
      .then(response => {
        if (response.data.isDuplicate) {
          alert('이미 사용중인 이메일입니다.');
        } else {
          sendVerificationCode(formData.email)
            .then(() => {
              alert('사용 가능한 이메일입니다. \n이메일에 전송된 인증번호를 확인해주세요.');
              setEmailSent(true);
              setCountdown(180); // 3 minutes countdown
            })
            .catch(error => {
              console.error('Error sending verification code:', error);
            });
        }
      })
      .catch(error => {
        console.error('Error checking email:', error);
      });
  };

  const handleVerificationCode = () => {
    verifyCode(formData.email, verificationCode)
      .then(response => {
        if (response.data.isValid) {
          setIsVerified(true);
          alert('인증이 완료되었습니다.');
        } else {
          alert('유효하지 않은 인증번호입니다.');
        }
      })
      .catch(error => {
        console.error('Error verifying code:', error);
      });
  };

  const handleUsernameCheck = () => {
    checkUsernameDuplication(formData.username)
      .then(response => {
        if (response.data.isDuplicate) {
          alert('이미 사용중인 닉네임입니다.');
          setUsernameAvailable(false);
        } else {
          alert('사용 가능한 닉네임입니다.');
          setUsernameAvailable(true);
        }
      })
      .catch(error => {
        console.error('Error checking username:', error);
      });
  };

  const validatePassword = (password) => {
    const lengthCondition = password.length >= 8;
    const hasLetter = /[a-zA-Z]/.test(password);
    const hasNumber = /[0-9]/.test(password);
    const hasSpecial = /[!@#$%^&*(),.?":{}|<>]/.test(password);
    const combinationCondition = (hasLetter && hasNumber) || (hasLetter && hasSpecial) || (hasNumber && hasSpecial);

    setPasswordConditions({
      length: lengthCondition,
      combination: combinationCondition
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!isVerified) {
      alert('이메일 인증을 진행해주세요.');
      return;
    }

    if (formData.password !== formData.confirmPassword) {
      alert('비밀번호가 일치하지 않습니다.');
      return;
    }

    if (!usernameAvailable) {
      alert('닉네임 중복 확인을 진행해주세요.');
      return;
    }

    if (!termsAccepted || !privacyAccepted) {
      alert('이용약관 및 개인정보 동의를 확인해주세요.');
      return;
    }

    const currentDate = new Date();
    const formattedDate = currentDate.toISOString().split('T')[0];

    const updatedFormData = { ...formData, join_date: formattedDate };
    signup(updatedFormData)
      .then(() => {
        alert('회원가입이 완료되었습니다. 로그인 페이지로 이동합니다.');
        navigate('/login');
      })
      .catch((error) => {
        console.error('Error signing up:', error);
      });
  };

  return (
    <>
      <LayoutClient />
      <div className='client-contents'>
        <div className='sign-wrap'>
          <div className='title-wrap'>
            <p className='title'>장비충닷컴에 오신것을 환영합니다.</p>
            <p className='desc'>장비충이 되어 보세요!</p>
          </div>
          <form onSubmit={handleSubmit} className='login-form'>
            <input
              type="email"
              name="email"
              placeholder="이메일 주소"
              value={formData.email}
              onChange={handleChange}
              required
            />
            {!isVerified && !emailSent && (
              <>
                <button type="button" onClick={handleEmailCheck} disabled={emailSent}>
                  계속하기
                </button>
              </>
            )}

            {emailSent && !isVerified && (
              <div className='verification-section'>
                <div className='authcode-wrap'>
                  <input
                    type="text"
                    name="verificationCode"
                    placeholder="인증번호 6자리"
                    value={verificationCode}
                    onChange={(e) => setVerificationCode(e.target.value)}
                    required
                  />
                  <span className='countdown'>
                    {Math.floor(countdown / 60)}:{String(countdown % 60).padStart(2, '0')}
                  </span>
                </div>
                <button type="button" onClick={handleVerificationCode}>인증요청</button>
              </div>
            )}

            {isVerified && (
              <div className='form-fields'>
                <div className='input-wrap'>
                  <input
                    type="text"
                    name="username"
                    placeholder="닉네임"
                    value={formData.username}
                    onChange={handleChange}
                    required
                  />
                  <button type="button" onClick={handleUsernameCheck} disabled={!isVerified}>
                    중복 확인
                  </button>
                </div>
                {usernameAvailable === null && (
                  <p></p>
                )}
                {usernameAvailable === true && (
                  <p className='txtConfirm'>사용 가능한 닉네임입니다.</p>
                )}
                {usernameAvailable === false && (
                  <p className='txtWarn'>이미 사용중인 닉네임입니다.</p>
                )}
                <div className='password-field'>
                  <input
                    type="password"
                    name="password"
                    placeholder="비밀번호"
                    value={formData.password}
                    onChange={handleChange}
                    required
                  />
                  <input
                    type="password"
                    name="confirmPassword"
                    placeholder="비밀번호 확인"
                    value={formData.confirmPassword}
                    onChange={handleChange}
                    required
                  />
                  <div className='password-guide'>
                    <p className='title'>비밀번호 요구사항</p>
                    <p className={passwordConditions.length ? 'valid' : 'invalid'}>
                    ✓ 비밀번호는 8글자 이상이어야 합니다.
                    </p>
                    <p className={passwordConditions.combination ? 'valid' : 'invalid'}>
                    ✓ 영문, 숫자, 기호 중 2개 이상의 조합이 필요합니다.
                    </p>
                  </div>
                </div>

                <div className='consent-section'>
                  <div className='agree'>
                    <label>
                      <input
                        type="checkbox"
                        checked={termsAccepted}
                        onChange={() => setTermsAccepted(!termsAccepted)}
                      />
                      이용약관에 동의합니다.
                    </label>
                    <button type="button" onClick={() => setShowTermsModal(true)}>자세히</button>
                  </div>
                  <div className='agree'>
                    <label>
                      <input
                        type="checkbox"
                        checked={privacyAccepted}
                        onChange={() => setPrivacyAccepted(!privacyAccepted)}
                      />
                      개인정보 처리방침에 동의합니다.
                    </label>
                    <button type="button" onClick={() => setShowPrivacyModal(true)}>자세히</button>
                  </div>
                </div>

                <button type="submit">계정생성</button>
              </div>
            )}
          </form>
          <div className='link-login'>
            이미 계정이 있으신가요?<Link to='/login'>로그인</Link>
          </div>
        </div>
      </div>
      <LayoutFooter />

      {/* 모달 컴포넌트 사용 */}
      <Modal isOpen={showTermsModal} onClose={() => setShowTermsModal(false)} title="이용약관">
        <p>이용약관 내용이 여기에 들어갑니다.</p>
      </Modal>
      <Modal isOpen={showPrivacyModal} onClose={() => setShowPrivacyModal(false)} title="개인정보 처리방침">
        <p>개인정보 처리방침 내용이 여기에 들어갑니다.</p>
      </Modal>
    </>
  );
};

export default Signup;