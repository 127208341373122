import React, { useState, useEffect } from 'react';
import { fetchGames, fetchGameById, fetchSettingsByGameId, deleteGame, createSetting, updateSetting, deleteSetting, createGame, updateGame } from '../../api';
import { Link } from 'react-router-dom';
import Layout from '../../components/layout/Layout';
import GameForm from '../../components/categoryGame/GameForm';
import SettingForm from '../../components/categoryGame/SettingForm';
import GameList from '../../components/categoryGame/GameList';

const App = () => {
  const [games, setGames] = useState([]);
  const [currentGameId, setCurrentGameId] = useState(null);
  const [settings, setSettings] = useState([]);
  const [settingForms, setSettingForms] = useState([]);
  const [settingsData, setSettingsData] = useState([]);
  const [deletedSettingIds, setDeletedSettingIds] = useState([]);

  const [gameData, setGameData] = useState({
    name: '',
    status: '진열함',
    exposure: '노출함',
    icon: null,
    banner: null,
    description: ''
  });

  useEffect(() => {
    console.log("Current games state:", games);
  }, [games]);

  useEffect(() => {
    loadGames();
  }, []);

  useEffect(() => {
    if (currentGameId !== null) {
      loadSettings(currentGameId);
      fetchGameById(currentGameId).then((response) => {
        const game = response.data.find((s) => s.id === currentGameId);
        setGameData(game);
      });
    } else {
      setSettings([]);
      setSettingForms([]);
      setSettingsData([]);
      setGameData({
        name: '',
        status: '진열함',
        exposure: '노출함',
        icon: null,
        banner: null,
        description: ''
      });
    }
  }, [currentGameId]);
  
  const loadGames = () => {
    fetchGames().then((response) => {
      setGames(response.data);
    }).catch((error) => {
      console.error("Error loading games:", error);
    });
  };

  const loadSettings = (gameId) => {
    setSettings([]);
    fetchSettingsByGameId(gameId).then((response) => {
      if (response.data.length > 0) {
        setSettings(response.data);
        setSettingForms(response.data.map(setting => ({ id: setting.id })));
        setSettingsData(response.data.map(setting => ({
          settingId: setting.id,
          formData: { category: setting.category, icon: setting.icon },
          dynamicFields: JSON.parse(setting.dynamic_fields || '[]'),
        })));
      } else {
        setSettings([]);
        setSettingForms([]);
        setSettingsData([]);
      }
    }).catch((error) => {
      console.error("Error loading settings:", error);
    });
  };

  const handleEditGame = (id) => {
    setCurrentGameId(id);
  };

  const handleSaveGame = (gameId, formDataToSend) => {
    return gameId ? updateGame(gameId, formDataToSend) : createGame(formDataToSend);
  };

  const handleDeleteGame = (id) => {
    deleteGame(id).then(() => {
      loadGames();
      if (id === currentGameId) {
        setSettings([]);
        setSettingForms([]);
        setCurrentGameId(null);
      }
      window.location.reload();
    }).catch((error) => {
      console.error("Error deleting game:", error);
    });
  };

  const handleAddSettingForm = () => {
    setSettingForms([...settingForms, { id: null }]);
    setSettingsData([...settingsData, { settingId: null, formData: {}, dynamicFields: [] }]);
  };

  const handleSettingChange = (index, setting) => {
    const updatedSettingsData = [...settingsData];
    updatedSettingsData[index] = setting;
    setSettingsData(updatedSettingsData);
  };

  const handleRemoveSettingForm = (index) => {
    const settingIdToRemove = settingsData[index].settingId;
    if (settingIdToRemove) {
      setDeletedSettingIds([...deletedSettingIds, settingIdToRemove]);
    }
    const updatedSettingForms = [...settingForms];
    updatedSettingForms.splice(index, 1);
    setSettingForms(updatedSettingForms);

    const updatedSettingsData = [...settingsData];
    updatedSettingsData.splice(index, 1);
    setSettingsData(updatedSettingsData);
  };

  const handleSaveAllSettings = async () => {
    try {

      // Save the game first
      const gameFormData = new FormData();
      gameFormData.append('name', gameData.name);
      gameFormData.append('status', gameData.status);
      gameFormData.append('exposure', gameData.exposure);
      gameFormData.append('description', gameData.description);
      gameFormData.append('icon', gameData.icon);
      gameFormData.append('banner', gameData.banner);

      const savedGame = await handleSaveGame(currentGameId, gameFormData);
      const savedGameId = currentGameId || savedGame.data.id;

      for (const settingId of deletedSettingIds) {
        await deleteSetting(settingId);
      }

      for (const setting of settingsData) {
        const form = new FormData();
        form.append('category', setting.formData.category);
        form.append('icon', setting.formData.icon);
        form.append('dynamicFields', JSON.stringify(setting.dynamicFields));

        if (setting.settingId) {
          await updateSetting(setting.settingId, form);
        } else {
          form.append('game_id', savedGameId);
          await createSetting(form);
        }
      }

      // Clear deleted setting IDs
      setDeletedSettingIds([]);

      // Refresh settings after saving
      loadSettings(savedGameId);
      setCurrentGameId(savedGameId);

      // Refresh games after saving
      loadGames();

      window.location.reload();

    } catch (error) {
      console.error('Error saving settings:', error);
    }
  };


  return (
    <>
      <Layout />
      <div className='contents'>
        <div className='titleArea'>분류 관리</div>
        <div className='subCate'>
          <Link to="/dashboard/category/game" className='selected'>게임 분류</Link>
          <Link to="/dashboard/category/team">팀 분류</Link>
          <Link to="/dashboard/category/toolCate">장비 분류</Link>
        </div>

        <div className='list-form'>
          <div className='list-wrap'>
            <div className='title-wrap'>
              <p>게임 리스트</p>
              <a href="/dashboard/category/game">게임 추가</a>
            </div>
            <GameList games={games} onEdit={handleEditGame} />
          </div>
          <div className='form-wrap'>
            <>
              <GameForm
                gameId={currentGameId}
                formData={gameData}
                setFormData={setGameData}
                onDelete={handleDeleteGame}
                onSaveAllSettings={handleSaveAllSettings}
              />
              <div className="sub-title">인게임 세팅 설정</div>
              {settingForms.map((setting, index) => (
                <SettingForm
                  key={index}
                  index={index}
                  gameId={currentGameId}
                  settingId={setting.id}
                  onChange={(data) => handleSettingChange(index, data)}
                  onRemove={() => handleRemoveSettingForm(index)}
                />
              ))}
              <div className='ingame-button'>
                <button onClick={handleAddSettingForm}>분류 추가</button>
              </div>
              <div className='bottom-button-wrap'>
                <button onClick={handleSaveAllSettings}>저장</button>
              </div>
            </>
          </div>
        </div>
      </div>
    </>
  );
};

export default App;