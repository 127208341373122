import React, { useState, useEffect } from 'react';
import { fetchBoards, createPost } from '../../api';
import { useNavigate, useParams } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import LayoutClient from '../../components/layout/LayoutClient';
import LayoutFooter from '../../components/layout/LayoutFooter';
import Quill from 'quill';
import ImageUploader from 'quill-image-uploader';

// Quill 모듈 등록
Quill.register('modules/imageUploader', ImageUploader);

// 이미지 업로드 최대 용량 (바이트 단위)
const MAX_IMAGE_SIZE = 2 * 1024 * 1024; // 2MB

// Quill 모듈 설정
const modules = {
  toolbar: [
    [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
    [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
    [{ size: [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ 'list': 'ordered' }, { 'list': 'bullet' },
     { 'indent': '-1' }, { 'indent': '+1' }],
    ['link', 'image', 'video'],
  ],
  clipboard: {
    matchVisual: false,
  },
  imageUploader: {
    upload: file => {
      return new Promise((resolve, reject) => {
        if (file.size > MAX_IMAGE_SIZE) {
          alert('2MB 이하의 파일을 업로드해주세요.');
          reject('Image size exceeds the maximum limit of 2MB');
          return;
        }

        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          resolve(reader.result);
        };
        reader.onerror = error => {
          reject('Image upload failed');
        };
      });
    }
  }
};

const Write = () => {
  const navigate = useNavigate();
  const { categoryId, parentCategory } = useParams();
  const [categories, setCategories] = useState([]);
  const [formData, setFormData] = useState({
    title: '',
    content: '',
    category_id: categoryId || ''
  });
  const [error, setError] = useState(null);

  useEffect(() => {
    loadCategories();
  }, [parentCategory]);

  const loadCategories = async () => {
    try {
      const response = await fetchBoards();
      const allCategories = response.data;

      // 대분류가 주어졌다면 해당 대분류에 속하는 하위 카테고리만 필터링
      if (parentCategory) {
        setCategories(allCategories.filter(category => category.parent_category === parentCategory));
      } else {
        // 대분류가 없으면, 모든 카테고리 로드
        setCategories(allCategories);
      }
    } catch (error) {
      console.error("Error loading categories:", error);
    }
  };

  useEffect(() => {
    // categoryId가 주어졌을 때 해당 카테고리 ID를 설정
    if (categoryId && categoryId !== 'null') {
      setFormData(prevFormData => ({ ...prevFormData, category_id: categoryId }));
    }
  }, [categoryId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleContentChange = (content) => {
    setFormData({ ...formData, content });
  };

  const getCategoryName = (id) => {
    const category = categories.find(category => category.id === id);
    return category ? encodeURIComponent(category.name) : 'Unknown';
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('token');

    if (!token) {
      setError('No token found.');
      return;
    }

    console.log(formData.category_id);

    if (formData.category_id == 'null') {
      alert('게시판 분류를 선택해주세요.');
      return;
    }

    try {
      const decodedToken = jwtDecode(token);
      const userId = decodedToken.id;

      await createPost(formData, userId, token);
      if (formData.category_id === 'null') {
        navigate(`/community/${parentCategory}`);
      } else {
        navigate(`/community/${parentCategory}`);
      }
    } catch (error) {
      console.error("Error creating post:", error);
    }
  };

  const formats = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image', 'video',
    'align',
  ];
  
  return (
    <div>
      <LayoutClient />
      <div className='client-contents'>
        <div className='inner'>
          <div className='title-wrap'>
            <p className='title'>글쓰기</p>
          </div>
          <div className='write-form'>
            <form onSubmit={handleSubmit}>
              <div className='write-group'>
                <label>카테고리</label>
                <select name="category_id" value={formData.category_id} onChange={handleChange}>
                  <option value="">카테고리를 선택해주세요</option>
                  {categories.map((category) => (
                    <option key={category.id} value={category.id}>{category.name}</option>
                  ))}
                </select>
              </div>
              <div className='write-group'>
                <label>제목</label>
                <input type="text" name="title" value={formData.title} onChange={handleChange} />
              </div>
              <div className='write-group'>
                <label>내용</label>
                <ReactQuill 
                  value={formData.content}
                  onChange={handleContentChange}
                  modules={modules}
                  formats={formats}
                />
              </div>
              <button type="submit">작성</button>
            </form>
          </div>
        </div>
      </div>
      <LayoutFooter />
    </div>
  );
};

export default Write;