import React, { useEffect, useState } from 'react';
import { fetchPostsCategory, fetchPosts, fetchBoards } from '../../api';
import { useNavigate, Link } from 'react-router-dom';

const BestList = ({ categoryId, posts, boards, parentCategory }) => {
  const navigate = useNavigate();

  const getCategoryName = (id) => {
    const category = boards.find(board => board.id === id);
    return category ? category.name : 'Unknown';
  };

  // 대분류에 대한 하위 카테고리만 필터링
  const filteredPosts = categoryId
    ? posts.filter(post => post.category_id === categoryId)
    : parentCategory
      ? posts.filter(post => {
          // 대분류에 속하는 하위 카테고리만 필터링
          const boardIds = boards.filter(board => board.parent_category === parentCategory).map(board => board.id);
          return boardIds.includes(post.category_id);
        })
      : posts;

  return (
    <>
      <div className='best-inner'>
        <ul>
          {posts.map((post, index) => (
            <li key={post.id}>
                <p className='no'>0{index + 1}</p>
                <p className='subject'>
                    <Link to={`/community/post/${post.id}`}>{post.title}</Link>
                </p>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default BestList;