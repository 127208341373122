import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { fetchToolById, fetchTools } from '../../api';

import Layout from '../../components/layout/Layout';
import ToolForm from '../../components/tool/ToolForm';

const App = () => {
  const toolId = useParams().id;
  const [tool, setTool] = useState(null);
  const [currentToolId, setCurrentToolId] = useState(null);

  useEffect(() => {
    if (toolId) {
      fetchToolById(toolId).then((response) => {
        console.log('Fetched tool:', response.data); 
        setTool(response.data);
      }).catch((error) => {
        console.error('There was an error fetching the tool!', error);
      });
    }
  }, [toolId]);

  const handleSaveTool = () => {
    fetchTools().then((response) => {
      setTool(response.data);
    }).catch(error => {
      console.error('There was an error fetching the tool!', error);
    });
    setCurrentToolId(null);
  };

  return (
    <>
      <Layout />
      <div className='contents'>
        <div className='titleArea'>장비 관리</div>
        <div className='single-form-wrap'>
          <ToolForm toolId={toolId} tool={tool} onSave={handleSaveTool} />
        </div>
      </div>
    </>
  );
};

export default App;