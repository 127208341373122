import React, { useState, useEffect } from 'react';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const BoardList = ({ boards, postCounts, onEdit, onDelete }) => {

  const [currentPage, setCurrentPage] = useState(1);
  const postsPerPage = 20;

  const indexOfLastTool = currentPage * postsPerPage;
  const indexOfFirstTool = indexOfLastTool - postsPerPage;
  const currentPosts = boards.slice(indexOfFirstTool, indexOfLastTool);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(boards.length / postsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <>
      <div className='table-body'>
        <ul>
          {currentPosts.map((board, index) => (
            <li key={board.id}>
              <div className='idx'>{index + 1}</div>
              <div className='category'>{board.parent_category}</div>
              <div className='title board-subject'>{board.name}</div>
              <div className='ttCnt'>{postCounts[board.id] || 0}</div>
              <div className='controll'>
                <button onClick={() => onEdit(board.id)}>수정</button>
                <button onClick={() => onDelete(board.id)} className='delete'>삭제</button>
              </div>
            </li>
          ))}
        </ul>
      </div>

      {/* 페이지네이션 버튼 */}
      <div className='pagination'>
        {pageNumbers.map(number => (
          <button key={number} onClick={() => paginate(number)} className={number === currentPage ? 'active' : ''}>
            {number}
          </button>
        ))}
      </div>
    </>

  );
};

export default BoardList;