import React, { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { jwtDecode } from 'jwt-decode';

const GoogleCallback = () => {
  const { login: loginContext } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const token = queryParams.get('token');
    const decodedToken = jwtDecode(token);
    console.log(decodedToken);
    if (token) {
      localStorage.setItem('token', token);
      loginContext(token);
      navigate('/');
    } else {
      alert('구글 인증이 실패했습니다.');
      navigate('/login');
    }
  }, [loginContext, navigate]);

  return <div>구글 로그인 중...</div>;
};

export default GoogleCallback;