import React, { useState, useEffect } from 'react';
import { fetchProfile, updateProfile } from '../../api';

const Profile = () => {
  const [formData, setFormData] = useState({ email: '', name: '' });

  useEffect(() => {
    loadProfile();
  }, []);

  const loadProfile = () => {
    fetchProfile()
      .then((response) => {
        setFormData(response.data);
      })
      .catch((error) => {
        console.error('Error loading profile:', error);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    updateProfile(formData)
      .then(() => {
        alert('Profile updated');
      })
      .catch((error) => {
        console.error('Error updating profile:', error);
      });
  };

  return (
    <div>
      <h2>Profile</h2>
      <form onSubmit={handleSubmit}>
        <input type="email" name="email" placeholder="Email" value={formData.email} onChange={handleChange} required />
        <input type="text" name="name" placeholder="Name" value={formData.name} onChange={handleChange} required />
        <button type="submit">Update</button>
      </form>
    </div>
  );
};

export default Profile;