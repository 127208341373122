import React, { useState, useEffect } from 'react';
import { createToolSetting, updateToolSetting, fetchToolSettingById } from '../../api';
import { useNavigate } from 'react-router-dom';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const ToolSettingForm = ({ toolSettingId, toolSetting, onSave, onDelete }) => {
  const navigate = useNavigate();
  const [previewIcon, setPreviewIcon] = useState(null); 
  const [formData, setFormData] = useState({
    name: '',
    icon: 'null',
    settings: []
  });

  useEffect(() => {
      if (toolSettingId) {
        toolSettingId = parseInt(toolSettingId);
          fetchToolSettingById(toolSettingId).then((response) => {
            const toolSetting = response.data.find((s) => s.id === toolSettingId);
            if (toolSetting.settings) {
              toolSetting.settings = JSON.parse(toolSetting.settings);
            }
            setFormData(toolSetting);
            if (toolSetting.icon && toolSetting.icon != 'null') {
              setPreviewIcon(`${SERVER_URL}${toolSetting.icon}`);
            } else if (toolSetting.icon == 'null') {
              setPreviewIcon(`${SERVER_URL}/uploads/no-image.svg`);
            } else {
              setPreviewIcon(`${SERVER_URL}/uploads/no-image.svg`);
            }
          });
      } else {
        setFormData({
          name: '',
          icon: 'null',
          settings: []
        });
      }
  }, [toolSettingId]);

  const handleIconChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      console.log(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData({ ...formData, icon: file }); // formData에 아이콘 파일 설정
        setPreviewIcon(reader.result); // 아이콘 미리보기 설정
      };
      reader.readAsDataURL(file);
    }
  };


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    console.log('change', formData);
  };

  const handleSettingChange = (index, e) => {
    const { name, value } = e.target;
    const newSettings = [...formData.settings];
    newSettings[index][name] = value;
    setFormData({ ...formData, settings: newSettings });
    
  };

  const handleSettingDelete = (index) => {
    const newSettings = [...formData.settings];
    newSettings.splice(index, 1);  // 즉시 삭제
    setFormData({ ...formData, settings: newSettings });
  };

  const handleSettingAdd = () => {
    setFormData({
      ...formData,
      settings: [...formData.settings, { name: '', type: 'text', defaultValue: '' }]
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData.icon)
    const formDataToSend = new FormData();
    formDataToSend.append('name', formData.name);
    formDataToSend.append('icon', formData.icon);
    const settingsString = typeof formData.settings === 'string' ? formData.settings : JSON.stringify(formData.settings);
    formDataToSend.append('settings', settingsString);
    
    if (toolSettingId) {
        updateToolSetting(toolSettingId, formDataToSend).then(() => {
            onSave();
            navigate('/dashboard/toolSetting/list');
        });
    } else {
        createToolSetting(formDataToSend).then(() => {
            onSave();
            navigate('/dashboard/toolSetting/list');
        });
    }
  };

  return (
    <form onSubmit={handleSubmit}>
        <div className='title-wrap'>
        {toolSettingId ? (
            <><p className="title">{formData.name}</p><div className='button-wrap'><button type="submit">수정 완료</button></div></>
          ) : (
            <><p className="title">새 장비 세팅</p><div className='button-wrap'><button type="submit">저장</button></div></>
          )}
        </div>
        <div className="sub-title">기본 설정</div>

        <div className="input-wrap">
          <p className="label">세팅명</p>
          <input type="text" name="name" value={formData.name} onChange={handleChange} placeholder="장비명" />
        </div>

        <div className="input-wrap">
          <p className="label">아이콘</p>
          {previewIcon && (<img src={previewIcon} className="iconPreview" alt="icon preview" />)}
          <label className="file-button">
            <input type="file" name="icon" onChange={handleIconChange} className='displaynone' accept="image/*" />
            {previewIcon ? '변경' : '추가'}
          </label>
        </div>

        <div className="input-wrap spec-wrap">
          <p className="label">정보 항목</p>
          <div className='field-wrap'>
            {formData.settings.map((setting, index) => (
              <div key={index} className='field spec'>
                <input type="text" name="name" value={setting.name} onChange={(e) => handleSettingChange(index, e)} placeholder="항목명" />
                <select name="type" value={setting.type} onChange={(e) => handleSettingChange(index, e)}>
                  <option value="text">문자열</option>
                  <option value="number">숫자</option>
                  <option value="date">날짜</option>
                </select>
                <input type="text" name="defaultValue" value={setting.defaultValue} onChange={(e) => handleSettingChange(index, e)} placeholder="기본값" />
                <button type="button" onClick={() => handleSettingDelete(index)} className='delete'>삭제</button>
              </div>
            ))}
            <button type="button" onClick={handleSettingAdd} className='btnAdd'>항목 추가</button>
          </div>
        </div>

        <button type="submit">저장</button>
    </form>
  );
};

export default ToolSettingForm;