import React, { useEffect, useState } from 'react';

const CategoryList = ({ onSelectCategory, parentCategory, boards }) => {
  const [categories, setCategories] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);

  useEffect(() => {
    // parentCategory에 따라 필터링
    if (boards.length > 0) {
      const filteredCategories = boards.filter(board => board.parent_category === parentCategory);
      setCategories(filteredCategories);
    }
  }, [parentCategory, boards]);

  // 카테고리 선택 핸들러
  const handleCategoryClick = (id) => {
    setSelectedCategoryId(id); // 선택한 카테고리 ID 저장
    onSelectCategory(id); // 부모 컴포넌트로 선택한 카테고리 ID 전달
  };

  // 선택한 카테고리 찾기
  const selectedCategory = categories.find(category => category.id === selectedCategoryId);

  return (
    <div className='board-title-wrap'>
      <h2>{selectedCategory ? selectedCategory.name : '전체'}</h2>
      <ul className='subCate-wrap'>
      <li
          className={selectedCategoryId === null ? 'selected' : ''}
          onClick={() => {
            setSelectedCategoryId(null);
            onSelectCategory(null);
          }}
        >
          전체
        </li>
        {categories.map(category => (
          <li
            key={category.id}
            className={category.id === selectedCategoryId ? 'selected' : ''}
            onClick={() => handleCategoryClick(category.id)}
          >
            {category.name}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CategoryList;