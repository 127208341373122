import React, { useState, useContext } from 'react';
import { login } from '../../api';
import { AuthContext } from '../../context/AuthContext';
import { useNavigate, Link } from 'react-router-dom';

import LayoutClient from '../../components/layout/LayoutClient';
import LayoutFooter from '../../components/layout/LayoutFooter';


const Login = () => {
  const [formData, setFormData] = useState({ email: '', password: '' });
  const { login: loginContext } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    login(formData)
      .then((response) => {
        localStorage.setItem('authToken', response.data.token); 
        loginContext(response.data.token); // 인증 상태 업데이트
        navigate('/');
      })
      .catch((error) => {
        alert('사용자 정보가 올바르지 않습니다. \n계정 및 비밀번호를 확인해주세요');
        console.error('Error logging in:', error);
      });
  };

  // 디스코드 로그인 버튼 클릭 핸들러
  const handleDiscordLogin = () => {
    window.location.href = 'https://jangbibug.gg/api/auth/discord';
  };

  // 구글 로그인 버튼 클릭 핸들러
  const handleGoogleLogin = () => {
    window.location.href = 'https://jangbibug.gg/api/auth/google';
  };

  const handleNaverLogin = () => {
    window.location.href = 'https://jangbibug.gg/api/auth/naver';
  };

  return (
    <>
      <LayoutClient></LayoutClient>
      <div className='client-contents'>
        <div className='login-wrap'>
          <div className='title-wrap'>
            <p className='title'>로그인</p>
            <p className='desc'>장비충닷컴에 오신것을 환영합니다.</p>
          </div>
          <form onSubmit={handleSubmit} className='login-form'>
            <input type="email" name="email" placeholder="이메일 주소" value={formData.email} onChange={handleChange} required />
            <input type="password" name="password" placeholder="비밀번호" value={formData.password} onChange={handleChange} required />
            <button type="submit">로그인</button>
          </form>
          <div className='sns-wrap'>
            <button onClick={handleGoogleLogin}><img src={`${process.env.PUBLIC_URL}/img/icon-google.svg`} /></button>
            <button onClick={handleNaverLogin}><img src={`${process.env.PUBLIC_URL}/img/icon-naver.svg`} /></button>
            <button onClick={handleDiscordLogin}><img src={`${process.env.PUBLIC_URL}/img/icon-discord.svg`} /></button>
          </div>
          <div className='link-join'>
            아직 장비충닷컴 회원이 아니신가요? <Link to='/signup'>회원가입</Link>
          </div>
        </div>
      </div>
      <LayoutFooter></LayoutFooter>
    </>

  );
};

export default Login;