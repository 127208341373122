import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { fetchPlayerById, fetchPlayers } from '../../api';

import Layout from '../../components/layout/Layout';
import PlayerForm from '../../components/player/PlayerForm';

const App = () => {
  const playerId = useParams().id;
  const [player, setPlayer] = useState(null);
  const [currentPlayerId, setCurrentPlayerId] = useState(null);

  useEffect(() => {
    if (playerId) {
      fetchPlayerById(playerId).then((response) => {
        setPlayer(response.data);
      }).catch((error) => {
        console.error('There was an error fetching the player!', error);
      });
    }
  }, [playerId]);

  const handleSavePlayer = () => {
    fetchPlayers().then((response) => {
      setPlayer(response.data);
    }).catch(error => {
      console.error('There was an error fetching the players!', error);
    });
    setCurrentPlayerId(null);
  };

  return (
    <>
      <Layout />
      <div className='contents'>
        <div className='titleArea'>선수 관리</div>
        <div className='single-form-wrap'>
          <PlayerForm playerId={playerId} player={player} onSave={handleSavePlayer} />
        </div>
      </div>
    </>
  );
};

export default App;