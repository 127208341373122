// components/layout/Layout.jsx

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './layout.css';

const Layout = () => {
    return (
        <>
            <div className='gnb'>
                <a href="/" target="_blank">웹 바로가기</a>
            </div>

            <div className='snb'>
                <Link to="/dashboard/category/game" className='logo-wrap'>ADMIN</Link>
                <Link to="/dashboard/category/game">분류 관리</Link>
                <Link to="/dashboard/toolSetting/list">장비 세팅 관리</Link>
                <Link to="/dashboard/player/list">선수 관리</Link>
                <Link to="/dashboard/tool/list">장비 관리</Link>
                <Link to="/dashboard/board/PostList">게시판 관리</Link>
                <Link to="/dashboard/member/list">회원 관리</Link>
            </div>
        </>
    )
}

export default Layout;
