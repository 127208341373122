import React, { useState, useEffect } from 'react';
import { createTool, updateTool, fetchToolById, fetchToolCates, fetchBrands, createBrand } from '../../api';
import { useNavigate } from 'react-router-dom';
import BrandModal from './BrandModal';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;
const defaultImage = `${SERVER_URL}/uploads/no-image.svg`;

const ToolForm = ({ toolId, tool, onSave, onDelete }) => {
  const navigate = useNavigate();
  const handleImageError = (e) => {
    e.target.src = defaultImage;
  }

  const [previewThumbnail, setPreviewThumbnail] = useState(null); 
  const [toolCates, setToolCates] = useState([]);
  const [subCates, setSubCates] = useState([]);
  const [specs, setSpecs] = useState([]);
  const [brands, setBrands] = useState([]);
  const [newKeyword, setNewKeyword] = useState('');
  const [formData, setFormData] = useState({
      name: '',
      cate1: '',
      cate2: '',
      thumbnail: null,
      link: '',
      exposure: '노출함',
      specs: specs.reduce((acc, spec) => {
        acc[spec.name] = {
          value: '',
          type: spec.type
        };
        return acc;
      }, {}),
      keywords: []
    });

  const [showBrandModal, setShowBrandModal] = useState(false);
  const [newBrand, setNewBrand] = useState('');
  

  useEffect(() => {
    if (toolId) {
      toolId = parseInt(toolId);
      fetchToolById(toolId).then((response) => {
        const tool = response.data.find((s) => s.id === toolId);

        let parsedSpecs = {};
        let parsedKeywords = [];
        try {
          if (typeof tool.keywords === 'string') {
            parsedKeywords = JSON.parse(tool.keywords);
          } else {
            parsedKeywords = tool.keywords || [];
          }
          parsedKeywords = JSON.parse(parsedKeywords);

          parsedSpecs = JSON.parse(tool.specs);
          parsedSpecs = JSON.parse(parsedSpecs); // 두 번 파싱
          
        } catch (error) {
          console.error('Error parsing specs:', error);
        }
        setFormData({
          name: tool.name || '',
          cate1: tool.cate1 || '',
          cate2: tool.cate2 || '',
          thumbnail: tool.thumbnail || null,
          link: tool.link || '',
          exposure: tool.exposure || '노출함',
          specs: parsedSpecs || {},
          keywords: parsedKeywords || []
        });
        if (tool.thumbnail) {
          setPreviewThumbnail(`${SERVER_URL}${tool.thumbnail}`);
        } else {
          setPreviewThumbnail(`${SERVER_URL}/uploads/no-image.svg`);
        }
      });
    } else {
      console.log('No tool data found')
    }
  }, [toolId]);

  const handleThumbnailChange = (e) => {
      const file = e.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setFormData({ ...formData, thumbnail: file });
          setPreviewThumbnail(reader.result);
        };
        reader.readAsDataURL(file);
      }
  };

  useEffect(() => {
    fetchToolCates().then(response => {
        setToolCates(response.data);
    }).catch(error => {
        console.error('There was an error fetching the category!', error);
    });

    fetchBrands().then(response => {
      setBrands(response.data);
    }).catch(error => {
      console.error('There was an error fetching brands!', error);
    });
  }, []);

  useEffect(() => {
    const filteredSubCates = toolCates.filter((cate) => cate.parent_id == formData.cate1);
    setSubCates(filteredSubCates);
  }, [formData.cate1, toolCates]);

  useEffect(() => {
    const selectedSubCate = subCates.find((cate) => cate.id == formData.cate2);
    if (selectedSubCate) {
      try {
        setSpecs(JSON.parse(selectedSubCate.specs || '[]'));
      } catch (error) {
        console.error('Error parsing specs:', error);
        setSpecs([]); // 에러 발생 시 빈 배열로 초기화
      }
    } else {
      setSpecs([]);
    }
  }, [formData.cate2, subCates]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name.startsWith('specs.')) {
        const [_, specKey, rangeKey] = name.split('.');

        if (rangeKey) {
            // 'range' 타입인 경우, min/max 값을 별도로 처리
            setFormData(prev => ({
                ...prev,
                specs: {
                    ...prev.specs,
                    [specKey]: {
                        ...prev.specs[specKey],
                        [rangeKey]: value
                    }
                }
            }));
        } else {
            setFormData({ ...formData, specs: { ...formData.specs, [specKey]: { value, type: specs.find(spec => spec.name === specKey).type } } });
        }
    } else {
        setFormData({ ...formData, [name]: value });
    }
};

const handleSubmit = (e) => {
  e.preventDefault();

  const displayedSpecs = {};
  specs.forEach(spec => {
      const specValue = formData.specs[spec.name];
      if (spec.type === 'range') {
          // 범위 타입일 경우 min/max 값을 포함하여 저장
          if (specValue?.min || specValue?.max) {
              displayedSpecs[spec.name] = {
                  min: specValue.min || '',
                  max: specValue.max || '',
                  type: spec.type
              };
          }
      } else if (specValue?.value) {
          displayedSpecs[spec.name] = {
              value: specValue.value,
              type: spec.type
          };
      }
  });

  const finalFormData = new FormData();
  finalFormData.append('exposure', formData.exposure);
  finalFormData.append('name', formData.name);
  finalFormData.append('cate1', formData.cate1);
  finalFormData.append('cate2', formData.cate2);
  finalFormData.append('thumbnail', formData.thumbnail);
  finalFormData.append('link', formData.link.startsWith('https://') ? formData.link : `https://${formData.link}`);
  finalFormData.append('specs', JSON.stringify(displayedSpecs));
  finalFormData.append('keywords', JSON.stringify(formData.keywords));

  if (toolId) {
    updateTool(toolId, finalFormData).then(() => {
      onSave();
      navigate('/dashboard/tool/list');
    });
  } else {
    createTool(finalFormData).then(() => {
      onSave();
      navigate('/dashboard/tool/list');
    });
  }
};

  const handleAddBrand = () => {
    createBrand({ name: newBrand })
    .then(response => {
      // 제조사 추가 후 목록 다시 가져오기
      fetchBrands().then(response => {
        setBrands(response.data);
        setNewBrand('');
        setShowBrandModal(false);
      }).catch(error => {
        console.error('Error fetching manufacturers:', error);
      });
    })
    .catch(error => {
      console.error('Error adding manufacturer:', error);
    });
  };

  const mainCategories = toolCates.filter((cate) => cate.parent_id == 'null');

  console.log(typeof formData.keywords);

  return (
    <>
    <form onSubmit={handleSubmit}>
        <div className='title-wrap'>
        {toolId ? (
            <><p className="title">{formData.name}</p><div className='button-wrap'><button type="submit">수정 완료</button></div></>
          ) : (
            <><p className="title">새 장비</p><div className='button-wrap'><button type="submit">저장</button></div></>
          )}
        </div>
        <div className="sub-title">기본 설정</div>
        
        <div className="input-wrap">
          <p className="label">노출여부</p>
          <label>
            <input type="radio" name="exposure" value="노출함" checked={formData.exposure === '노출함'} onChange={handleChange} />
            노출함
          </label>
          <label>
            <input type="radio" name="exposure" value="노출안함" checked={formData.exposure === '노출안함'} onChange={handleChange} />
            노출안함
          </label>
        </div>

        <div className="input-wrap">
            <p className="label">대분류</p>
            <select name="cate1" value={formData.cate1} onChange={handleChange}>
            <option value="">분류를 선택해주세요</option>
            {mainCategories.map((toolCate) => (
                <option key={toolCate.id} value={toolCate.id}>
                {toolCate.name}
                </option>
            ))}
            </select>
        </div>
        
        <div className="input-wrap">
            <p className="label">하위분류</p>
            <select name="cate2" value={formData.cate2} onChange={handleChange}>
            <option value="">분류를 선택해주세요</option>
            {subCates.map((toolCate) => (
                <option key={toolCate.id} value={toolCate.id}>
                {toolCate.name}
                </option>
            ))}
            </select>
        </div>

        <div className="input-wrap">
          <p className="label">장비명</p>
          <input type="text" name="name" value={formData.name} onChange={handleChange} placeholder="장비명" />
        </div>
        
        <div className="input-wrap">
          <p className="label">썸네일</p>
          {previewThumbnail && (<img src={previewThumbnail} className="iconPreview" alt="icon preview" onError={handleImageError} />)}
          <label className="file-button">
            <input type="file" name="thumbnail" onChange={handleThumbnailChange} className='displaynone' accept="image/*" />
            {previewThumbnail ? '변경' : '추가'}
          </label>

        </div>

        <div className="input-wrap">
          <p className="label">구매링크</p>
          <div className='sns-wrap'>
            <div className='sns link'>
              <div className='sns-input'>
                <span className="fixed-text">https://</span>
                <input type="text" name="link" value={formData.link.replace(/^https:\/\//, '')} onChange={handleChange} placeholder="구매 링크" className="sns-input" />
              </div>
            </div>
          </div>
        </div>

        <div className="input-wrap">
          <p className="label">검색 키워드</p>
          <div class="key-wrap">
            <div className="keywords-input-wrap">
              <input
                type="text"
                value={newKeyword}
                onChange={(e) => setNewKeyword(e.target.value)} // 입력 필드의 값을 상태로 저장
                placeholder="키워드를 입력하세요"
              />
              <button
                type="button"
                onClick={() => {
                  if (newKeyword && !formData.keywords.includes(newKeyword)) {
                    setFormData((prev) => ({
                      ...prev,
                      keywords: [...prev.keywords, newKeyword] // 키워드 추가
                    }));
                    setNewKeyword(''); // 입력 후 필드 비우기
                  }
                }}
              >
                추가
              </button>
            </div>

            <div className="keywords-list">
              <p className='title'>추가된 키워드</p>
              <div className='add-key'>
                {Array.isArray(formData.keywords) && formData.keywords.map((keyword, index) => (
                  <div key={index} className="keyword-item">
                    {keyword}
                    <button
                      type="button"
                      className="delete"
                      onClick={() => {
                        setFormData((prev) => ({
                          ...prev,
                          keywords: prev.keywords.filter((_, i) => i !== index) // 키워드 삭제
                        }));
                      }}
                    >
                      ×
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="sub-title">상세 정보 설정</div>

        {specs.map((spec, index) => (
          <div className="input-wrap spec" key={index}>
            <p className="label">{spec.name}</p>
            <div className='spec-input-wrap'>
            {spec.name === '브랜드' ? (
              <>
                <select
                  name={`specs.${spec.name}`}
                  value={formData.specs[spec.name]?.value || ''}
                  onChange={handleChange}
                >
                  <option value="">브랜드를 선택하세요</option>
                  {brands.map((brand) => (
                    <option key={brand.id} value={brand.name}>
                      {brand.name}
                    </option>
                  ))}
                </select>
                <button type="button" onClick={() => setShowBrandModal(true)}>
                  신규 추가
                </button>
              </>
            ) : spec.type === 'number' ? (
              <input
                type="number"
                name={`specs.${spec.name}`}
                value={formData.specs[spec.name]?.value || ''}
                onChange={handleChange}
              />
            ) : spec.type === 'range' ? (
              <>
                <span className='guide'>최소값</span>
                <input
                  type="number"
                  className='input-range'
                  name={`specs.${spec.name}.min`}
                  value={formData.specs[spec.name]?.min || ''}
                  onChange={handleChange}
                  placeholder="최소값"
                />
                <span className='guide'>최대값</span>
                <input
                  type="number"
                  className='input-range'
                  name={`specs.${spec.name}.max`}
                  value={formData.specs[spec.name]?.max || ''}
                  onChange={handleChange}
                  placeholder="최대값"
                />
              </>
            ) : (
              <input
                type="text"
                name={`specs.${spec.name}`}
                value={formData.specs[spec.name]?.value || ''}
                onChange={handleChange}
              />
            )}
            </div>
          </div>
        ))}
        {showBrandModal && (
          <BrandModal
            newBrand={newBrand}
            setNewBrand={setNewBrand}
            onAddBrand={handleAddBrand}
            onClose={() => setShowBrandModal(false)}
            brands={brands} // 이 부분을 추가합니다.
          />
        )}
        <button type="submit" className='bottom-center-button-wrap'>저장</button>
    </form>
    </>
  );
};

export default ToolForm;