// components/layout/Layout.jsx

import React, { useState, useContext, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { fetchGames } from '../../api';
import { AuthContext } from '../../context/AuthContext';
import './layoutClient.css';

const LayoutClient = () => {
    const { isAuthenticated, logout } = useContext(AuthContext);
    const [games, setGames] = useState([]);
    const [showGames, setShowGames] = useState(false);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [isGamesListOpen, setIsGamesListOpen] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        fetchGames().then((response) => {
            setGames(response.data);
        }).catch((error) => {
            console.error('Failed to fetch games:', error);
        });
    }, []);

    const toggleSidebar = () => {
        setIsSidebarOpen(false);
    };

    const handleMouseEnter = () => {
        setShowGames(true);
    };

    const handleMouseLeave = () => {
        setShowGames(false);
    };

    const handleLogout = () => {
        logout(); // 로그아웃 처리
        navigate('/'); // 메인 페이지로 이동
    };

    return (
        <>
            <div className='header'>
                <div className='front-section'>
                    <div className='logo-wrap'><Link to="/"><img src={`${process.env.PUBLIC_URL}/img/top-logo.png`} /></Link></div>
                    <div className='category-wrap'>
                        <div 
                            className='dropdown'
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                        >
                            <Link to="/game">게임</Link>
                            {showGames && (
                                <div className='dropdown-content'>
                                    {games.filter(
                                        (game) => game.exposure == '노출함'
                                    ).map((game) => (
                                        <Link key={game.id} to={`/game/detail/${game.id}`}>
                                            {game.name}
                                        </Link>
                                    ))}
                                </div>
                            )}
                        </div>
                        <Link to="/community/커뮤니티">커뮤니티</Link>
                        <Link to="/compare">장비비교</Link>
                        <Link to="/community/장비리뷰">장비리뷰</Link>
                    </div>
                </div>
                <div className='btn-sidebar' onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
                    ☰
                </div>
                <div className={`sidebar ${isSidebarOpen ? 'open' : ''}`}>
                    <div className='menu-list'>
                        <div className={`game-link ${isGamesListOpen ? 'open' : ''}`} onClick={() => setIsGamesListOpen(!isGamesListOpen)} >게임<img src={`${process.env.PUBLIC_URL}/img/icon-arrow-down.svg`} /></div>
                        <div className={`dropdown-content ${isGamesListOpen ? 'open' : ''}`} >
                            {games.filter(
                                (game) => game.exposure == '노출함'
                            ).map((game) => (
                                <Link key={game.id} to={`/game/detail/${game.id}`}  onClick={toggleSidebar}>
                                    {game.name}
                                </Link>
                            ))}
                        </div>
                        <Link to="/community/커뮤니티" onClick={toggleSidebar}>커뮤니티</Link>
                        <Link to="/compare" onClick={toggleSidebar}>장비비교</Link>
                        <Link to="/community/장비리뷰" onClick={toggleSidebar}>장비리뷰</Link>
                    </div>
                    <div className='action-wrap'>
                        {isAuthenticated ? (
                            <>
                                <Link to="/mypage" className='mypage' onClick={toggleSidebar}>마이페이지</Link>
                                <button onClick={handleLogout} className='logout'>로그아웃</button>
                            </>
                        ) : (
                            <>
                                <Link to="/login" className='login' onClick={toggleSidebar}>로그인</Link>
                                <Link to="/signup" className='signup' onClick={toggleSidebar}>회원가입</Link>
                            </>
                        )}
                    </div>
                </div>
                <div className='util-wrap'>
                    {isAuthenticated ? (
                        <>
                            <button onClick={handleLogout}>로그아웃</button>
                            <Link to="/mypage" className='btn-mypage'><img src={`${process.env.PUBLIC_URL}/img/icon-mypage.svg`} /></Link>
                            
                        </>
                    ) : (
                        <>
                            <Link to="/login">로그인</Link>
                            <Link to="/signup" className='signup'>회원가입</Link>
                        </>
                    )}
                </div>
            </div>
        </>
    )
}

export default LayoutClient;
