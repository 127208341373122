import React, { useState, useEffect, useContext } from 'react';
import { fetchTeams, fetchGames, addFavoritePlayer, removeFavoritePlayer, fetchFavoritePlayers } from '../../api';
import { AuthContext } from '../../context/AuthContext';
import { Link } from 'react-router-dom';
import Flags from 'react-world-flags';
import { jwtDecode } from 'jwt-decode';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const PlayerList = ({ players }) => {
  const { isAuthenticated } = useContext(AuthContext);
  const [teamMap, setTeamMap] = useState({});
  const [gameMap, setGameMap] = useState({});
  const [favorites, setFavorites] = useState(new Set());
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // 팀과 게임 정보를 가져옵니다.
        const teamResponse = await fetchTeams();
        const gameResponse = await fetchGames();
        
        // 팀 정보를 매핑합니다.
        const teamMap = teamResponse.data.reduce((acc, team) => {
          acc[team.id] = { name: team.name, icon: team.icon };
          return acc;
        }, {});
        setTeamMap(teamMap);

        // 게임 정보를 매핑합니다.
        const gameMap = gameResponse.data.reduce((acc, game) => {
          acc[game.id] = game.name;
          return acc;
        }, {});
        setGameMap(gameMap);

        // 인증된 사용자일 경우 즐겨찾기 정보를 가져옵니다.
        if (isAuthenticated) {
          const token = localStorage.getItem('token');
          if (token) {
            const userId = jwtDecode(token).id;
            const favoriteResponse = await fetchFavoritePlayers(userId, token);
            const favoritePlayers = new Set(favoriteResponse.data.map(player => player.id));
            setFavorites(favoritePlayers);
          }
        }
      } catch (error) {
        console.error('There was an error fetching data:', error);
        setError('Failed to load data.');
      }
    };

    fetchData();
  }, [isAuthenticated]);


  const handleFavoriteToggle = async (playerId) => {
    if (!isAuthenticated) {
      if (window.confirm('로그인 후 이용 가능합니다. 로그인 페이지로 이동하시겠습니까?')) {
        window.location.href = '/login';
      }
      return;
    }

    const token = localStorage.getItem('token');
    if (!token) {
      setError('User not authenticated.');
      return;
    }

    const userId = jwtDecode(token).id;

    try {
      if (favorites.has(playerId)) {
        await removeFavoritePlayer(userId, playerId, token);
        setFavorites(prev => {
          const newFavorites = new Set(prev);
          newFavorites.delete(playerId);
          return newFavorites;
        });
      } else {
        await addFavoritePlayer(playerId, token);
        setFavorites(prev => new Set(prev).add(playerId));
      }
    } catch (error) {
      console.error('Failed to toggle favorite status:', error);
      setError('Failed to toggle favorite status.');
    }
  };

  return (
    <>
      {players.map((player, index) => (
        <div key={player.id}>
          <Link to={`/player/detail/${player.id}`}>
            <div className='thumb-wrap'>
              {player.thumbnail ? (
                <img src={`${SERVER_URL}${player.thumbnail}`} alt='thumbnail' />
              ) : (
                <img src={`${SERVER_URL}/uploads/no-image.svg`} alt='no thumbnail' />
              )}
            </div>
            <p className='playId'>{player.playId}</p>
            <div className='name'>
              {player.nationality && (
                <Flags code={player.nationality} style={{ width: 16, height: 10, marginLeft: 8 }} />
              )}
              {player.name}
            </div>
            <div className='team'>
              {teamMap[player.team_id] ? (
                <>
                  <img src={`${SERVER_URL}${teamMap[player.team_id].icon}`} alt='team icon' style={{ width: 24, height: 24, marginRight: 4 }} />
                  {teamMap[player.team_id].name}
                </>
              ) : 'Unknown'}
            </div>
          </Link>
          {isAuthenticated && (
            <button className={`${favorites.has(player.id) ? 'disactive' : 'active'}`} onClick={() => handleFavoriteToggle(player.id)}>
              <img
                src={`${process.env.PUBLIC_URL}/img/${favorites.has(player.id) ? 'icon-heart-d.svg' : 'icon-heart.svg'}`}
                alt={favorites.has(player.id) ? 'Unfollow' : 'Follow'}
                className="favorite-icon"
              />
              {favorites.has(player.id) ? '팔로우 취소' : '팔로우'}
            </button>
          )}
          {!isAuthenticated && (
            <button onClick={() => {
              if (window.confirm('로그인 후 이용 가능합니다. 로그인 페이지로 이동하시겠습니까?')) {
                window.location.href = '/login';
              }
            }}>
              즐겨찾기
            </button>
          )}
          
        </div>
      ))}
    </>
  );
};

export default PlayerList;