import React, { useState, useEffect } from 'react';
import { fetchTeams, fetchSettingsByTeamId, deleteTeam } from '../../api';
import { Link } from 'react-router-dom';

import Layout from '../../components/layout/Layout';
import TeamForm from '../../components/categoryTeam/TeamForm';
import TeamList from '../../components/categoryTeam/TeamList';

const App = () => {
  const [teams, setTeams] = useState([]);
  const [currentTeamId, setCurrentTeamId] = useState(null);

  useEffect(() => {
    console.log("Current teams state:", teams);
  }, [teams]);

  useEffect(() => {
    loadTeams();
  }, []);

  useEffect(() => {
    if (currentTeamId !== null) {
    }
  }, [currentTeamId]);
  
  const loadTeams = () => {
    fetchTeams().then((response) => {
      setTeams(response.data);
    }).catch((error) => {
      console.error("Error loading teams:", error);
    });
  };

  const handleEditTeam = (id) => {
    setCurrentTeamId(id);
  };

  const handleSaveTeam = () => {
    setCurrentTeamId(null);
    loadTeams();
  };

  const handleDeleteTeam = (id) => {
    deleteTeam(id).then(() => {
      loadTeams();
      if (id === currentTeamId) {
        setCurrentTeamId(null);
      }
      window.location.reload();
    }).catch((error) => {
      console.error("Error deleting game:", error);
    });
  };

  return (
    <>
      <Layout />
      <div className='contents'>
        <div className='titleArea'>분류 관리</div>
        <div className='subCate'>
          <Link to="/dashboard/category/game">게임 분류</Link>
          <Link to="/dashboard/category/team" className='selected'>팀 분류</Link>
          <Link to="/dashboard/category/toolCate">장비 분류</Link>
        </div>

        <div className='list-form'>
          <div className='list-wrap'>
            <div className='title-wrap'>
              <p>팀 리스트</p>
              <a href="/dashboard/category/team">팀 추가</a>
            </div>
            <TeamList teams={teams} onEdit={handleEditTeam} />
          </div>
          <div className='form-wrap'>
            {currentTeamId ? (
              <>
                <TeamForm teamId={currentTeamId} onSave={handleSaveTeam}  onDelete={handleDeleteTeam}/>
              </>
            ) : (
              <TeamForm onSave={handleSaveTeam} />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default App;