import React, { useState, useEffect } from 'react';

const BrandModal = ({ newBrand, setNewBrand, onAddBrand, onClose, brands }) => {
  const [errorMessage, setErrorMessage] = useState('');

  const handleAddBrand = () => {
    // 중복 체크 (대소문자 무시)
    const isDuplicate = brands.some(
      (brand) => brand.name.toLowerCase() === newBrand.trim().toLowerCase()
    );

    if (isDuplicate) {
      setErrorMessage('이미 등록된 브랜드입니다.');
    } else {
      onAddBrand();
    }
  };

  useEffect(() => {
    // 입력값이 변경될 때마다 에러 메시지 초기화
    setErrorMessage('');
  }, [newBrand]);

  return (
    <div className="modal">
      <div className="modal-dialog">
        <div className="modal-contents">
          <div className="modal-header">
            <div className="modal-title h4">브랜드 추가</div>
            <button type="button" className="btn-close" onClick={onClose}></button>
          </div>
          <div className="input-wrap">
            <p className="label">브랜드명</p>
            <input
              type="text"
              value={newBrand}
              onChange={(e) => setNewBrand(e.target.value)}
              placeholder="브랜드명"
            />
          </div>
          {errorMessage && <p className="error-message">{errorMessage}</p>}
          </div>
        <button type="button" onClick={handleAddBrand} className="modalSubmit">
          등록
        </button>
      </div>
    </div>
  );
};

export default BrandModal;