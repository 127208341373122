import React from 'react';
import CommentItem from './CommentItem';

const CommentList = ({ comments, onAddReply }) => {
  return (
    <ul className='comment-list'>
      {comments.map(comment => (
        <CommentItem key={comment.id} comment={comment} onAddReply={onAddReply} />
      ))}
    </ul>
  );
};

export default CommentList;