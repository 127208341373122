import React, { useState, useEffect } from 'react';
import { fetchBoards, fetchPosts } from '../../api';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const PostList = ({ posts, onDelete, onPinPost }) => {
  const [boards, setboards] = useState([]);
  const [boardsMap, setboardsMap] = useState({});

  useEffect(() => {
    // 게시판 목록 가져오기
    fetchBoards().then(response => {
        setboards(response.data);
        // 게시판 ID와 이름 매핑 객체 생성
        const boardsMap = response.data.reduce((acc, board) => {
            acc[board.id] = board.name;
            return acc;
        }, {});
        setboardsMap(boardsMap);
    }).catch(error => {
        console.error('There was an error fetching the board!', error);
    });
  }, []);

  const [currentPage, setCurrentPage] = useState(1);
  const postsPerPage = 20;

  const indexOfLastTool = currentPage * postsPerPage;
  const indexOfFirstTool = indexOfLastTool - postsPerPage;
  const currentPosts = posts.slice(indexOfFirstTool, indexOfLastTool);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(posts.length / postsPerPage); i++) {
    pageNumbers.push(i);
  }

  const handlePinToggle = (postId, currentPinStatus) => {
    const newPinStatus = !currentPinStatus; // 현재 핀 상태의 반대 값으로 설정
    onPinPost(postId, newPinStatus);
  };


  return (
    <>
      <div className='table-body'>
        <ul>
          {currentPosts.map((post, index) => (
            <li key={post.id}>
              <div className='idx'>{index + 1}</div>
              <div className='game'>{boardsMap[post.category_id] || 'Unknown'}</div> 
              <div className='game'>{post.author}</div>
              <div className='player posts'><a href={`/community/post/${post.id}`} target='_blank'>{post.title}</a></div>
              <div className='game'>{post.commentCount}</div>
              <div className='game'>{post.views}</div>
              <div className='game'>{post.likes}</div>
              <div className='controll'>
                <button onClick={() => handlePinToggle(post.id, post.is_pinned)} className={post.is_pinned == 1 ? 'active' : 'disactive'}>
                  {post.is_pinned == 1 ? '고정됨' : '고정'}
                </button>
                <button onClick={() => onDelete(post.id)} className='delete'>삭제</button>
              </div>
            </li>
          ))}
        </ul>
      </div>

      {/* 페이지네이션 버튼 */}
      <div className='pagination'>
        {pageNumbers.map(number => (
          <button key={number} onClick={() => paginate(number)} className={number === currentPage ? 'active' : ''}>
            {number}
          </button>
        ))}
      </div>
    </>

  );
};

export default PostList;