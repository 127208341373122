import React, { useState, useEffect } from 'react';
import { createBoard, updateBoard, fetchBoardById } from '../../api';
import { useNavigate } from 'react-router-dom';

const BoardForm = ({ boardId, onSave, onDelete }) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    parent_category: '',
    exposure: ''
  });

  useEffect(() => {
    if (boardId) {
      fetchBoardById(boardId).then((response) => {
        var chkId = parseInt(boardId);
        const board = response.data.find((s) => s.id === chkId);
        setFormData({
          name: board.name || '',
          parent_category: board.parent_category || '',
          exposure: board.exposure || ''
        });
      });
    }
  }, [boardId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    console.log(formData);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (boardId) {
      updateBoard(boardId, formData).then((onSave) => {
        navigate('/dashboard/board/list');
      });
    } else {
      createBoard(formData).then((onSave) => {
        navigate('/dashboard/board/list');
      });
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="title-wrap">
        <p className="title">
          {boardId ? '게시판 수정' : '새 게시판'}
        </p>
        <div className='button-wrap'>
          <button type="submit">저장</button>
          {boardId && (
            <button type="button" onClick={() => onDelete(boardId)} className='delete'>삭제</button>
          )}
        </div>
      </div>
      <div className="input-wrap">
        <p className="label">제목</p>
        <input type="text" name="name" value={formData.name} onChange={handleChange} placeholder="게시판명" />
      </div>
      <div className="input-wrap">
        <p className="label">대분류</p>
        <select name="parent_category" value={formData.parent_category} onChange={handleChange}>
            <option value="">대분류를 선택해주세요</option>
            <option value="커뮤니티">커뮤니티</option>
            <option value="장비리뷰">장비리뷰</option>
        </select>
      </div>

      <div className="input-wrap">
        <p className="label">사용여부</p>
        <label>
          <input type="radio" name="exposure" value="사용함" checked={formData.exposure === '사용함'} onChange={handleChange} />
          사용함
        </label>
        <label>
          <input type="radio" name="exposure" value="사용안함" checked={formData.exposure === '사용안함'} onChange={handleChange} />
          사용안함
        </label>
      </div>
    </form>
  );
};

export default BoardForm;