import React, { useState, useEffect } from 'react';
import { fetchToolCates } from '../../api';
import ReactSlider from 'react-slider';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;
const defaultImage = `${SERVER_URL}/uploads/no-image.svg`;

const CompareList = ({ tools }) => {
  const handleImageError = (e) => {
    e.target.src = defaultImage;
  }
  const [toolCates, setToolCates] = useState([]);
  const [toolCatesMap, setToolCatesMap] = useState({});
  const [headers, setHeaders] = useState([]);
  const [filteredTools, setFilteredTools] = useState([]);
  const [filters, setFilters] = useState({});  
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });


  useEffect(() => {
    // 장비분류 목록 가져오기
    fetchToolCates().then(response => {
        setToolCates(response.data);
        // 장비분류 ID와 이름 매핑 객체 생성
        const toolCatesMap = response.data.reduce((acc, toolCate) => {
            acc[toolCate.id] = toolCate.name;
            return acc;
        }, {});
        setToolCatesMap(toolCatesMap);
    }).catch(error => {
        console.error('There was an error fetching the toolcate!', error);
    });
  }, []);

  useEffect(() => {
    const allSpecs = tools.map(tool => {
      let specsObj = {};
      try {
        specsObj = JSON.parse(tool.specs); // 한 번만 파싱
      } catch (error) {
        console.error('Error parsing specs JSON:', error);
      }
      return specsObj;
    });
  
    const uniqueHeaders = new Set();
    const specOptions = {};
    const specCounts = {};
    const rangeSpecs = {};
  
    allSpecs.forEach(spec => {
      Object.keys(spec).forEach(key => {
        uniqueHeaders.add(key);
  
        if (spec[key].type === 'text') {
          if (!specOptions[key]) {
            specOptions[key] = new Set();
            specCounts[key] = {};
          }
          const value = spec[key].value;
          specOptions[key].add(value);
  
          if (!specCounts[key][value]) {
            specCounts[key][value] = 0;
          }
          specCounts[key][value]++;
        }
  
        if (spec[key].type === 'number') {
          if (!rangeSpecs[key]) {
            rangeSpecs[key] = { min: 0, max: null }; // 최소값을 0으로 설정
          }
          const minValue = parseFloat(spec[key].value);
          const maxValue = parseFloat(spec[key].value);
  
          // 최소값이 0보다 작으면 0으로 설정
          if (minValue < rangeSpecs[key].min) {
            rangeSpecs[key].min = 0;
          }
  
          // 최대값 설정
          if (rangeSpecs[key].max === null || maxValue > rangeSpecs[key].max) {
            rangeSpecs[key].max = maxValue;
          }

          rangeSpecs[key].type = 'number'
        }
  
        if (spec[key].type === 'range') {
          if (!rangeSpecs[key]) {
            rangeSpecs[key] = { min: 0, max: null }; // 최소값을 0으로 설정
          }
          const minValue = parseFloat(spec[key].min || 0);
          const maxValue = parseFloat(spec[key].max || 0);
  
          if (minValue < rangeSpecs[key].min) {
            rangeSpecs[key].min = Math.max(0, minValue);
          }
  
          if (rangeSpecs[key].max === null || maxValue > rangeSpecs[key].max) {
            rangeSpecs[key].max = maxValue;
          }
          rangeSpecs[key].type = 'range'

        }
      });
    });

    setHeaders(Array.from(uniqueHeaders));
  
    setFilters(prevFilters => {
      const updatedFilters = { ...prevFilters };
      console.log(rangeSpecs);
      for (const [key, options] of Object.entries(specOptions)) {
        updatedFilters[key] = {
          type: 'text',
          options: Array.from(options),
          selected: [],
          counts: specCounts[key]
        };
      }

      
      for (const [key, range] of Object.entries(rangeSpecs)) {
        if (rangeSpecs[key].type == 'number') {
          rangeSpecs[key].min = 0;
          updatedFilters[key] = {
            type: 'number',
            min: parseFloat(range.min), // 숫자 변환
            max: parseFloat(range.max),
            range: [parseFloat(range.min), parseFloat(range.max)]
          };
        }
      }

      // 범위(range) 필터 처리 추가
      for (const [key, range] of Object.entries(rangeSpecs)) {
        if (rangeSpecs[key].type == 'range') {
          rangeSpecs[key].min = 0;
          updatedFilters[key] = {
            type: 'range',
            min: parseFloat(range.min),
            max: parseFloat(range.max),
            range: [parseFloat(range.min), parseFloat(range.max)] // range 값을 설정
          };
        }
        
      }
      return updatedFilters;
    });

    setFilteredTools(tools);
  }, [tools]);

  const applyFilters = () => {
    const newFilteredTools = tools.filter(tool => {
      let specsObj = {};
      try {
        specsObj = JSON.parse(tool.specs);
      } catch (error) {
        console.error('Error parsing specs JSON:', error);
      }

      for (const [key, filter] of Object.entries(filters)) {
        if (filter.type === 'number') {
          const value = specsObj[key] ? parseFloat(specsObj[key].value) : null;
          if (value === null || value < filter.range[0] || value > filter.range[1]) {
            return false;
          }
        } else if (filter.type === 'text') {
          const value = specsObj[key] ? specsObj[key].value : '';

          if (filter.selected.length > 0 && !filter.selected.includes(value)) {
            return false;
          }
        } else if (filter.type === 'range') {
          if (filter.range[0] !== filter.min || filter.range[1] !== filter.max) {
            const rangeObj = specsObj[key] || {};
            const rangeMin = parseFloat(rangeObj.min) || 0;
            const rangeMax = parseFloat(rangeObj.max) || 0;

            if (!(rangeMin >= filter.range[0] && rangeMax <= filter.range[1])) {
              return false;
            }
          }
        }
      }
  
      return true;
    });
  
    setFilteredTools(newFilteredTools);
  };

  const handleBrandChange = (e) => {
    const { name, value, checked } = e.target;
    setFilters(prevFilters => {
      const updatedFilters = { ...prevFilters };
      if (updatedFilters[name]) {
        updatedFilters[name].selected = checked
          ? [...updatedFilters[name].selected, value]
          : updatedFilters[name].selected.filter(item => item !== value);
      }
      return updatedFilters;
    });
  };

  const handleSliderChange = (key, values) => {
    setFilters(prevFilters => {
      const updatedFilters = { ...prevFilters };
      if (updatedFilters[key]) {
        updatedFilters[key].range = values;
      }
      return updatedFilters;
    });
  };

  const handleInputChange = (header, type, value) => {
    setFilters(prevFilters => {
      const updatedFilters = { ...prevFilters };
      const newRange = [...updatedFilters[header].range];
      
      if (type === 'min') {
        newRange[0] = Math.max(parseFloat(value), updatedFilters[header].min);  // 최소값 제한
      } else if (type === 'max') {
        newRange[1] = Math.min(parseFloat(value), updatedFilters[header].max);  // 최대값 제한
      }
      
      updatedFilters[header].range = newRange;
      return updatedFilters;
    });
  };

  const resetFilters = () => {
    // Reset filters to initial state
    setFilters({});
    setFilteredTools(tools);
  };

  const renderFilterControls = () => {
    return headers.map(header => {
      const filter = filters[header];
      if (!filter) return null;
  
      if (filter.type === 'number' || filter.type === 'range') {
        return (
          <div key={header} className='filter-group'>
            <div class="label-wrap">
              <label>{header}</label>
            </div>
            <div className="slide-wrap">
              <div className="input-wrap">
                <input
                  type="number"
                  value={filter.range[0]}
                  onChange={(e) => handleInputChange(header, 'min', e.target.value)}
                  min={filter.min}
                  max={filter.max}
                />
                <span> - </span>
                <input
                  type="number"
                  value={filter.range[1]}
                  onChange={(e) => handleInputChange(header, 'max', e.target.value)}
                  min={filter.min}
                  max={filter.max}
                />
              </div>
              <ReactSlider
                value={filter.range}
                onChange={values => handleSliderChange(header, values)}
                min={filter.min}
                max={filter.max}
                step={1}
                className={filter.range[0] !== filter.min || filter.range[1] !== filter.max ? 'slider-modified horizontal-slider' : 'slider-default horizontal-slider'}
                thumbClassName='thumb'
                trackClassName='track'
                ariaLabel={['Min value', 'Max value']}
                renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
              />
            </div>
          </div>
        );
      }
  
      if (filter.type === 'text') {
        return (
          <div key={header} className='filter-group'>
            <label><span>{header}</span>
              <div className='check-wrap'>
              {filter.options.map(option => (
                <label key={option}>
                  <input
                    type="checkbox"
                    name={header}
                    value={option}
                    checked={filter.selected.includes(option)}
                    onChange={handleBrandChange}
                  />
                  {option} ({filter.counts[option]})
                </label>
              ))}
              </div>
            </label>
          </div>
        );
      }
  
      return null;
    });
  };

  const renderSpecs = (specs) => {
    try {
      // 두 번 파싱을 해야 하는 부분 수정
      const specsObj = JSON.parse(specs); 
      
      // headers에 맞춰서 각 spec을 출력
      return headers.map(header => {
        const spec = specsObj[header];
        if (spec) {
          // 범위 타입이면 min, max 출력
          if (spec.type === 'range') {
            return (
              <td key={header}>
                <span class="hd-name">{header}</span>
                {spec.min} ~ {spec.max}
              </td>
            );
          } else if (spec.value !== undefined) {
            // 값이 있을 경우 처리
            return (
              <td key={header}><span class="hd-name">{header}</span>
                {spec.type === 'number' ? spec.value.toLocaleString() : spec.value}
              </td>
            );
          }
        }
        // 값이 없을 경우 빈 칸 처리
        return <td key={header}></td>;
      });
    } catch (error) {
      console.error('Error parsing specs JSON:', error);
      // 오류 발생 시, 모든 테이블 셀을 빈 칸으로 처리
      return headers.map(() => <td></td>);
    }
  };

  useEffect(() => {
    let sortedTools = [...tools];
  
    if (sortConfig.key) {
      sortedTools.sort((a, b) => {
        const specsA = JSON.parse(a.specs || '{}');
        const specsB = JSON.parse(b.specs || '{}');
  
        const valueA = specsA[sortConfig.key]?.value || '';
        const valueB = specsB[sortConfig.key]?.value || '';
  
        if (sortConfig.direction === 'asc') {
          return valueA > valueB ? 1 : valueA < valueB ? -1 : 0;
        } else {
          return valueA < valueB ? 1 : valueA > valueB ? -1 : 0;
        }
      });
    }
  
    setFilteredTools(sortedTools);
  }, [tools, sortConfig]);

  const handleHeaderClick = (key) => {
    setSortConfig(prevConfig => ({
      key,
      direction: prevConfig.key === key && prevConfig.direction === 'asc' ? 'desc' : 'asc',
    }));
  };

  return (
    <div className='client-table-wrap tool-list-wrap'>
      <div className='filters'>
        <div className='title'>필터</div>
        {renderFilterControls()}
        <button onClick={applyFilters}>조회</button>
      </div>
      <div className='table-head'>
        <div className='idx'>No</div>
        <div className='game'>장비분류</div>
        <div className='tool'>장비</div>
        {headers.map(header => (
          <div key={header} className="spec-header" onClick={() => handleHeaderClick(header)} >
            {header}
            <div class="spec-inner">
              <span className={ sortConfig.key === header && sortConfig.direction === 'asc' ? 'active' : '' } > ▲ </span>
              <span className={ sortConfig.key === header && sortConfig.direction === 'desc' ? 'active' : '' } > ▼ </span>
            </div>
          </div>
        ))}
      </div>
      <div className='table-body'>
        {filteredTools.map((tool, index) => (
          <div className='table-row' key={tool.id}>
            <div className='idx'>{index + 1}</div>
            <div className='game'>{toolCatesMap[tool.cate2] || 'Unknown'}</div>
            <div className='tool'>
              {tool.thumbnail ? (
                <img src={`${SERVER_URL}${tool.thumbnail}`} onError={handleImageError} />
              ) : (
                <img src={defaultImage} />
              )}
              {tool.link.length > 8 ? (
                <a href={tool.link} target="_blank">{tool.name}</a>
              ): (
                <p>{tool.name}</p>
              )}
             
            </div>
            {renderSpecs(tool.specs)}
          </div>
        ))}
      </div>
    </div>
  );
};

export default CompareList;