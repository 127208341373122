import React, { useState, useEffect } from 'react';
import { createTeam, updateTeam, deleteTeam, fetchTeamById } from '../../api';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const TeamForm = ({ teamId, onSave, onDelete }) => {
  const [previewIcon, setPreviewIcon] = useState(null); 
  const [formData, setFormData] = useState({
    name: '',
    exposure: '노출함',
    icon: null,
    description: ''
  });

  const chkId = teamId;

  useEffect(() => {
    if (teamId) {
      fetchTeamById(teamId).then((response) => {
        const team = response.data.find((s) => s.id === teamId);
        setFormData(team);
        if (team.icon) {
          setPreviewIcon(`${SERVER_URL}${team.icon}`);
        } else {
          setPreviewIcon(`${SERVER_URL}/uploads/no-image.svg`);
        }
      });
    }
  }, [teamId]);

  const handleIconChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData({ ...formData, icon: file }); // formData에 아이콘 파일 설정
        setPreviewIcon(reader.result); // 아이콘 미리보기 설정
      };
      reader.readAsDataURL(file);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formDataToSend = new FormData();
    formDataToSend.append('name', formData.name);
    formDataToSend.append('exposure', formData.exposure);
    formDataToSend.append('icon', formData.icon);
    formDataToSend.append('description', formData.description);
    if (teamId) {
      updateTeam(teamId, formDataToSend).then(onSave);
    } else {
      createTeam(formDataToSend).then(onSave);
    }
    //window.location.reload();
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="title-wrap">
            {chkId ? (
                <><p className="title"><img src="/img/icon-folder-active.png" />{formData.name}</p><div className='button-wrap'><button type="submit">수정 완료</button><button onClick={() => onDelete(teamId)} className='delete'>삭제</button></div></>
              ) : (
                <><p className="title"><img src="/img/icon-folder-active.png" />새 팀</p><div className='button-wrap'><button type="submit">저장</button></div></>
              )}
        </div>
        <div className="sub-title">기본 설정</div>
        <div className="input-wrap">
          <p className="label">팀명</p>
          <input type="text" name="name" value={formData.name} onChange={handleChange} placeholder="팀 이름" />
        </div>
        <div className="input-wrap">
          <p className="label">노출여부</p>
          <label>
            <input type="radio" name="exposure" value="노출함" checked={formData.exposure === '노출함'} onChange={handleChange} />
            노출함
          </label>
          <label>
            <input type="radio" name="exposure" value="노출안함" checked={formData.exposure === '노출안함'} onChange={handleChange} />
            노출안함
          </label>
        </div>
        <div className="input-wrap">
          <p className="label">로고</p>
          {previewIcon && (<img src={previewIcon} className="iconPreview" alt="icon preview" />)}
          <label className="file-button">
            <input type="file" name="icon" onChange={handleIconChange} className='displaynone' accept="image/*" />
            {previewIcon ? '변경' : '추가'}
          </label>
        </div>
        <div className="input-wrap">
          <p className="label">팀 설명 </p>
          <textarea name="description" value={formData.description} onChange={handleChange} placeholder="설명"></textarea>
        </div>
      </form>
    </>
    
  );
};

export default TeamForm;