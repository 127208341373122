import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { fetchBoardById, fetchBoards } from '../../api';

import Layout from '../../components/layout/Layout';
import BoardForm from '../../components/board/BoardForm';

const App = () => {
  const boardId = useParams().id;
  const [board, setBoard] = useState(null);
  const [currentBoardId, setCurrentBoardId] = useState(null);

  useEffect(() => {
    if (boardId) {
      console.log(boardId);
      fetchBoardById(boardId).then((response) => {
        console.log('Fetched board:', response.data); 
        setBoard(response.data);
      }).catch((error) => {
        console.error('There was an error fetching the board!', error);
      });
    }
  }, [boardId]);

  const handleSaveBoard = () => {
    fetchBoards().then((response) => {
      setBoard(response.data);
    }).catch(error => {
      console.error('There was an error fetching the boards!', error);
    });
    setCurrentBoardId(null);
  };

  return (
    <>
      <Layout />
      <div className='contents'>
        <div className='titleArea'>게시판 관리</div>
        <div className='single-form-wrap'>
          <BoardForm boardId={boardId} board={board} onSave={handleSaveBoard} />
        </div>
      </div>
    </>
  );
};

export default App;