import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { verifyAdminToken } from '../api';

const AdminRoute = ({ element }) => {
    const [isAdmin, setIsAdmin] = useState(null);

    useEffect(() => {
        const checkAdminAuth = async () => {
            const token = localStorage.getItem('adminToken');
            if (!token) {
                setIsAdmin(false);
                return;
            }

            try {
                const response = await verifyAdminToken(token);
                setIsAdmin(response.data.isAdmin); // 서버에서 관리자인지 여부를 반환
            } catch (error) {
                setIsAdmin(false);
            }
        };

        checkAdminAuth();
    }, []);

    if (isAdmin === null) return <div>Loading...</div>; // 인증 상태를 확인하는 동안 로딩 표시

    if (isAdmin) {
        return element;
    } else {
        return <Navigate to="/dashboard/login" />;
    }
};

export default AdminRoute;