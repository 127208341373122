import React, { useState } from 'react';

const TeamList = ({ teams, onEdit, onDelete }) => {
  const [selectedTeamId, setSelectedTeamId] = useState(null);

  const handleEditClick = (teamId) => {
    setSelectedTeamId(teamId);
  };

  return (
    <ul>
      {teams.map((team) => (
        <li key={team.id}>
          <button
            onClick={() => {
              onEdit(team.id);
              handleEditClick(team.id);
            }}
            className={selectedTeamId === team.id ? 'selected' : ''}
          >
            {team.name}
          </button>
          <button onClick={() => onDelete(team.id)} className='displaynone'>삭제</button>
        </li>
      ))}
    </ul>
  );
};

export default TeamList;