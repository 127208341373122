import React, { useState } from 'react';

const GameList = ({ games, onEdit, onDelete }) => {
  const [selectedGameId, setSelectedGameId] = useState(null);

  const handleEditClick = (gameId) => {
    setSelectedGameId(gameId);
  };

  return (
    <ul>
      {games.map((game) => (
        <li key={game.id}>
          <button
            onClick={() => {
              onEdit(game.id);
              handleEditClick(game.id);
            }}
            className={selectedGameId === game.id ? 'selected' : ''}
          >
            {game.name}
          </button>
          <button onClick={() => onDelete(game.id)} className='displaynone'>삭제</button>
        </li>
      ))}
    </ul>
  );
};

export default GameList;