import axios from 'axios';

const API_URL = 'https://jangbibug.gg/api';
//const API_URL = 'http://localhost:3001/api';

// 관리자 API 추가
export const adminLogin = (data) => {
  return axios.post(`${API_URL}/admin/login`, data);
};

export const verifyAdminToken = (token) => {
  return axios.post(`${API_URL}/admin/verify`, {}, {
    headers: { 'Authorization': `Bearer ${token}` }
  });
};

export const login = (data) => {
  return axios.post(`${API_URL}/users/login`, data);
};

export const signup = (data) => {
  return axios.post(`${API_URL}/users/signup`, data);
};

export const fetchProfile = () => {
  const token = localStorage.getItem('token');
  return axios.get(`${API_URL}/users/profile`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

export const updateProfile = (data) => {
  return axios.put(`${API_URL}/users/profile`, data);
};

export const checkEmailDuplication = (email) => {
  return axios.post(`${API_URL}/users/check-email`, { email });
};

export const checkUsernameDuplication = (username) => {
  return axios.post(`${API_URL}/users/check-username`, { username });
};

export const sendVerificationCode = (email) => {
  return axios.post(`${API_URL}/users/send-verification-code`, { email });
};

export const verifyCode = (email, verificationCode) => {
  return axios.post(`${API_URL}/users/verify-code`, { email, verificationCode });
};

export const updateUsername = (userId, newUsername, token) => {
  return axios.put(
    `${API_URL}/users/${userId}/username`, 
    { username: newUsername },
    { 
      headers: {
        Authorization: `Bearer ${token}`,
      }
    }
  );
};

// Member APIs
export const fetchUsers = () => {
  return axios.get(`${API_URL}/users/memberList`);
};

export const deleteUser = (id, data) => {
  return axios.delete(`${API_URL}/users/${id}`);
};

export const changeUserGrade = (userIds, newGradeId) => {
  return axios.post(`${API_URL}/users/change-grade`, { userIds, newGradeId });
};

export const getProfile = () => {
  return axios.get(`${API_URL}/users`, {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    }
  });
};

export const fetchUserData = (id) => {
  return axios.get(`${API_URL}/users/${id}`);
};

export const fetchUserPosts = async (userId, token) => {
  const response = await fetch(`${API_URL}/posts/user/${userId}`, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });
  return response.json();
};

export const assignPenalty = (userId) => {
  return axios.post(`${API_URL}/users/penalty`, { userId });
};

export const cancelPenalty = (userId) => {
  return axios.delete(`${API_URL}/users/penalty/${userId}`);
};

export const fetchUserPenaltyStatus = async (userId) => {
  try {
    const response = await axios.get(`${API_URL}/users/${userId}/penalty-status`);
    return response.data;
  } catch (error) {
    console.error('Error fetching penalty status:', error);
    throw error;
  }
};


// Member > MembershipGrade APIs
export const fetchMemberships = () => {
  return axios.get(`${API_URL}/membershipGrades`);
};

export const fetchMembershipById = (id) => {
  return axios.get(`${API_URL}/membershipGrades/${id}`);
};

export const createMembership = (data) => {
  return axios.post(`${API_URL}/membershipGrades`, data);
};

export const updateMembership = (id, data) => {
  return axios.put(`${API_URL}/membershipGrades/${id}`, data);
};

export const deleteMembership = (id, data) => {
  return axios.delete(`${API_URL}/membershipGrades/${id}`);
};

export const fetchDefaultMemberships = () => {
  return axios.get(`${API_URL}/membershipGrades/default`);
};


//Game 카테고리 APIs
export const fetchGames = () => {
  return axios.get(`${API_URL}/games`);
};

export const fetchGameById = (id) => {
  return axios.get(`${API_URL}/games/${id}`);
};

export const createGame = (data) => {
  return axios.post(`${API_URL}/games`, data);
};

export const updateGame = (id, data) => {
  return axios.put(`${API_URL}/games/${id}`, data);
};

export const deleteGame = (id, data) => {
  return axios.delete(`${API_URL}/games/${id}`);
};

export const fetchSettingsByGameId = (gameId) => {
  return axios.get(`${API_URL}/settings/game/${gameId}`);
};

export const fetchSettings = () => {
  return axios.get(`${API_URL}/settings`);
};

export const createSetting = (data) => {
  return axios.post(`${API_URL}/settings`, data);
};

export const updateSetting = (id, data) => {
  return axios.put(`${API_URL}/settings/${id}`, data);
};

export const deleteSetting = (id) => {
  return axios.delete(`${API_URL}/settings/${id}`);
};

export const fetchSettingById = (id) => {
  return axios.get(`${API_URL}/settings/${id}`);
};


//Team 카테고리 APIs
export const fetchTeams = () => {
  return axios.get(`${API_URL}/teams`);
};

export const fetchTeamById = (id) => {
  return axios.get(`${API_URL}/teams/${id}`);
};

export const createTeam = (data) => {
  return axios.post(`${API_URL}/teams`, data);
};

export const updateTeam = (id, data) => {
  return axios.put(`${API_URL}/teams/${id}`, data);
};

export const deleteTeam = (id, data) => {
  return axios.delete(`${API_URL}/teams/${id}`);
};


//Player APIs
export const fetchPlayers = () => {
  return axios.get(`${API_URL}/players`);
};

export const fetchPlayerById = (id) => {
  return axios.get(`${API_URL}/players/${id}`);
};

export const createPlayer = (data) => {
  return axios.post(`${API_URL}/players`, data);
};

export const updatePlayer = (id, data) => {
  return axios.put(`${API_URL}/players/${id}`, data);
};

export const deletePlayer = (id, data) => {
  return axios.delete(`${API_URL}/players/${id}`);
};

// Player Comment APIs
export const fetchCommentsByPlayerId = (playerId) => {
  return axios.get(`${API_URL}/players/${playerId}/comments`);
};

export const playerCreateComment = (playerId, userId, content, parentId = null) => {
  return axios.post(`${API_URL}/players/${playerId}/comments`, { userId, content, parentId });
};

// 댓글 수정 (선택사항)
export const playerUpdateComment = (playerId, commentId, content) => {
  return axios.put(`${API_URL}/players/${playerId}/comments/${commentId}`, { content });
};

// 댓글 삭제 (선택사항)
export const playerDeleteComment = (playerId, commentId) => {
  return axios.delete(`${API_URL}/players/${playerId}/comments/${commentId}`);
};


//Tool APIs
export const fetchTools = () => {
  return axios.get(`${API_URL}/tools`);
};

export const fetchToolsByCategoryID = (cate2Id) => {
  return axios.get(`${API_URL}/tools/category/${cate2Id}`);
};

export const fetchToolById = (id) => {
  return axios.get(`${API_URL}/tools/${id}`);
};

export const createTool = (data) => {
  return axios.post(`${API_URL}/tools`, data);
};

export const updateTool = (id, data) => {
  return axios.put(`${API_URL}/tools/${id}`, data);
};

export const deleteTool = (id, data) => {
  return axios.delete(`${API_URL}/tools/${id}`);
};

export const updateToolSpecs = async (toolCateId, deletedSpecs) => {
  try {
    const response = await axios.post(`${API_URL}/tools/updateToolSpecs`, {
      toolCateId,
      deletedSpecs
    });
    return response.data;
  } catch (error) {
    console.error("Error updating tool specs:", error);
    throw error;
  }
};

//Tool Brand APIs
export const fetchBrands = () => {
  return axios.get(`${API_URL}/brand`);
};

export const createBrand = (data) => {
  return axios.post(`${API_URL}/brand`, data);
};

//Tool Setting APIs
export const fetchToolSettings = () => {
  return axios.get(`${API_URL}/toolSettings`);
};

export const fetchToolSettingById = (id) => {
  return axios.get(`${API_URL}/toolSettings/${id}`);
};

export const createToolSetting = (data) => {
  return axios.post(`${API_URL}/toolSettings`, data);
};

export const updateToolSetting = (id, data) => {
  return axios.put(`${API_URL}/toolSettings/${id}`, data);
};

export const deleteToolSetting = (id, data) => {
  return axios.delete(`${API_URL}/toolSettings/${id}`);
};

//Tool 카테고리 APIs
export const fetchToolCates = () => {
  return axios.get(`${API_URL}/toolCates`);
};

export const fetchToolCateById = (id) => {
  return axios.get(`${API_URL}/toolCates/${id}`);
};

export const createToolCate = (data) => {
  return axios.post(`${API_URL}/toolCates`, data);
};

export const updateToolCate = (id, data) => {
  return axios.put(`${API_URL}/toolCates/${id}`, data);
};

export const deleteToolCate = (id, data) => {
  return axios.delete(`${API_URL}/toolCates/${id}`);
};

export const updateToolCateOrder = (toolCateId, newOrder) => {
  return axios.put(`${API_URL}/toolCates/${toolCateId}/order`, { order: newOrder })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

// Board APIs
export const fetchBoards = () => {
  return axios.get(`${API_URL}/boards`);
};

export const fetchBoardById = (id) => {
  return axios.get(`${API_URL}/boards/${id}`);
};

export const createBoard = (data) => {
  return axios.post(`${API_URL}/boards`, data);
};

export const updateBoard = (id, data) => {
  return axios.put(`${API_URL}/boards/${id}`, data);
};

export const deleteBoard = (id) => {
  return axios.delete(`${API_URL}/boards/${id}`);
};

// Board - Post APIs
export const fetchPosts = () => {
  return axios.get(`${API_URL}/posts/`);
};

export const fetchPostsCategory = (id) => {
  return axios.get(`${API_URL}/posts/category/${id}`);
};

export const fetchPostById = (id) => {
  return axios.get(`${API_URL}/posts/${id}`);
};

export const fetchBestPostsCategory = async (categoryId) => {
  return axios.get(`${API_URL}/posts/best-posts/${categoryId}`);
};

export const reportPost = (postId, userId) => {
  return axios.post(`${API_URL}/posts/report/${postId}`, { userId });
};


export const pinPost = (postId, pin) => {
  console.log(pin);
  return axios.put(`${API_URL}/posts/${postId}/pin-priority`, { pin });
};

export const fetchBestPosts = (category) => {
  return axios.get(`${API_URL}/posts/best-posts`, { params: { category }
  });
};

// HTML 콘텐츠에서 이미지 URL을 추출하는 함수
export const extractImageUrlsFromContent = (content) => {
  const imageUrls = [];
  const parser = new DOMParser();
  const doc = parser.parseFromString(content, 'text/html');
  
  doc.querySelectorAll('img').forEach(img => {
    if (img.src) {
      imageUrls.push(img.src);
    }
  });

  return imageUrls;
}; 

export const createPost = (postData, userId, token) => {
  const formData = new FormData();
  formData.append('title', postData.title);
  formData.append('content', postData.content);
  formData.append('category_id', postData.category_id);
  formData.append('user_id', userId);

  // Quill 에디터에서 이미지 URL을 추출하고 추가
  const imageUrls = extractImageUrlsFromContent(postData.content); // 이 함수는 이미지 URL을 추출하는 데 필요합니다.
  imageUrls.forEach((url, index) => formData.append(`image_${index}`, url));

  return axios.post(`${API_URL}/posts`, formData, {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const updatePost = (id, data) => {
  return axios.put(`${API_URL}/posts/${id}`, data);
};

export const deletePost = (id) => {
  return axios.delete(`${API_URL}/posts/${id}`);
};

export const incrementPostLikes = (id) => {
  return axios.post(`${API_URL}/posts/${id}/like`);
};

export const decrementPostLikes = (id) => {
  return axios.post(`${API_URL}/posts/${id}/unlike`);
};

export const checkUserLikeStatus = async (postId, userId) => {
  const response = await axios.get(`${API_URL}/posts/likes/status`, {
    params: {
      post_id: postId,
      user_id: userId,
    },
  });
  return response;
};

export const toggleLikeStatus = async (postId, userId) => {
  const response = await axios.post(`${API_URL}/posts/likes/toggle`, {
    post_id: postId,
    user_id: userId,
  });
  return response;
};

export const fetchCommentsByPostId = (postId) => {
  return axios.get(`${API_URL}/posts/${postId}/comments`);
};

export const postComment = (postId, userId, content, parentId = null) => {
  return axios.post(`${API_URL}/posts/${postId}/comments`, { userId, content, parentId });
};

// 댓글 수정 (선택사항)
export const updateComment = (commentId, content) => {
  return axios.put(`${API_URL}/posts/comments/${commentId}`, { content });
};

// 댓글 삭제 (선택사항)
export const deleteComment = (commentId) => {
  return axios.delete(`${API_URL}/posts/comments/${commentId}`);
};

export const fetchUserComments = async (userId, token) => {
  const response = await fetch(`${API_URL}/posts/comments/user/${userId}`, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });
  return response.json();
};


// 즐겨찾기
export const addFavoritePlayer = (playerId, token) => {
  return axios.post(`${API_URL}/favorites`, { playerId }, {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });
};

export const fetchFavoritePlayers = (userId, token) => {
  return axios.get(`${API_URL}/favorites/${userId}`, {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });
};

export const removeFavoritePlayer = (userId, playerId, token) => {
  return axios.delete(`${API_URL}/favorites/${userId}/${playerId}`, {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });
};
