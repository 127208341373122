import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';

import Layout from '../components/layout/Layout';

const DashboardMain = () => {
  const navigate = useNavigate();
  return (
    <>
      <Layout />
      <div className='contents'>
        <div className='titleArea'>Jangbibug.gg 어드민 페이지입니다.</div>
      </div>
    </>
  );
};

export default DashboardMain;