import React, { useState, useEffect } from 'react';
import { fetchToolCates } from '../../api';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;
const defaultImage = `${SERVER_URL}/uploads/no-image.svg`;


const ToolList = ({ tools, onEdit, onDelete }) => {
  const handleImageError = (e) => {
    e.target.src = defaultImage;
  }
  const [toolCates, setToolCates] = useState([]);
  const [toolCatesMap, setToolCatesMap] = useState({});

  useEffect(() => {
    // 장비분류 목록 가져오기
    fetchToolCates().then(response => {
        setToolCates(response.data);
        // 장비분류 ID와 이름 매핑 객체 생성
        const toolCatesMap = response.data.reduce((acc, toolCate) => {
            acc[toolCate.id] = toolCate.name;
            return acc;
        }, {});
        setToolCatesMap(toolCatesMap);
    }).catch(error => {
        console.error('There was an error fetching the toolcate!', error);
    });
  }, []);

  const [currentPage, setCurrentPage] = useState(1);
  const postsPerPage = 20;

  const indexOfLastTool = currentPage * postsPerPage;
  const indexOfFirstTool = indexOfLastTool - postsPerPage;
  const currentPosts = tools.slice(indexOfFirstTool, indexOfLastTool);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(tools.length / postsPerPage); i++) {
    pageNumbers.push(i);
  }


  return (
    <>
      <div className='table-body'>
        <ul>
          {currentPosts.map((tool, index) => (
            <li key={tool.id}>
              <div className='idx'>{index + 1}</div>
              <div className='game'>{toolCatesMap[tool.cate1] || 'Unknown'}</div> 
              <div className='game'>{toolCatesMap[tool.cate2] || 'Unknown'}</div>
              <div className='player' onClick={() => onEdit(tool.id)}>
                {tool.thumbnail ? (
                  <img src={`${SERVER_URL}${tool.thumbnail}`}  onError={handleImageError} />
                ) : (
                  <img src={`${SERVER_URL}/uploads/no-image.svg`} />
                )}
                {tool.name}
              </div>
              <div className='controll'>
                <button onClick={() => onEdit(tool.id)}>수정</button>
                <button onClick={() => onDelete(tool.id)} className='delete'>삭제</button>
              </div>
            </li>
          ))}
        </ul>
      </div>

      {/* 페이지네이션 버튼 */}
      <div className='pagination'>
        {pageNumbers.map(number => (
          <button key={number} onClick={() => paginate(number)} className={number === currentPage ? 'active' : ''}>
            {number}
          </button>
        ))}
      </div>


    </>

  );
};

export default ToolList;