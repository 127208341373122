import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { fetchGameById, fetchPlayers, fetchTeams, fetchGames } from '../../api';

import LayoutClient from '../../components/layout/LayoutClient';
import LayoutFooter from '../../components/layout/LayoutFooter';
import PlayerList from './PlayerList';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const GameDetail = () => {
  const { id } = useParams();
  const [game, setGame] = useState([]);

  const [players, setPlayers] = useState([]);
  const [teams, setTeams] = useState([]);
  const [games, setGames] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState('');
  const [selectedGame, setSelectedGame] = useState('');
  const [searchName, setSearchName] = useState('');
  const [filteredPlayers, setFilteredPlayers] = useState([]);
  const [filteredTeams, setFilteredTeams] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    loadGameDetails(); // 게임 상세 로드
    loadPlayers(); // 선수 목록 로드
    loadTeams(); // 팀 목록 로드
    loadGames(); // 게임 목록 로드

    if (id) {
      setSelectedGame(id);
      filterTeamsByGame(id);
    }

  }, [id]);

  const loadGameDetails = () => {
    fetchGameById(id).then((response) => {
      setGame(response.data[0]);
      setSelectedGame(id); // URL 파라미터와 일치하는 게임 선택
    }).catch((error) => {
      console.error("Error loading game details:", error);
    });
  };


  const loadPlayers = () => {
    fetchPlayers().then((response) => {
      setPlayers(response.data);
      setFilteredPlayers(response.data);
    }).catch((error) => {
      console.error("Error loading players:", error);
    });
  };
  
  const loadTeams = () => {
    fetchTeams().then(response => {
      setTeams(response.data);
    }).catch(error => {
      console.error('Error loading teams:', error);
    });
  };

  const loadGames = () => {
    fetchGames().then(response => {
      setGames(response.data);
    }).catch(error => {
      console.error('Error loading games:', error);
    });
  };

  const handleFilter = () => {
    if (!players.length) return; // 선수 목록이 로드되지 않았으면 필터링하지 않음
    const newFilteredPlayers = players.filter(player => {
      const matchTeam = selectedTeam === '' || player.team_id === selectedTeam;
      const matchGame = selectedGame === '' || player.game === selectedGame;
      const matchName =
        searchName === '' ||
        player.playId.toLowerCase().includes(searchName.toLowerCase()) ||
        player.name.toLowerCase().includes(searchName.toLowerCase());
  
        let matchKeywords = false;
        if (player.keywords) {
          try {
            let keywordsArray = player.keywords;
          
            // tool.keywords가 string일 경우 JSON.parse()로 배열로 변환
            if (typeof keywordsArray === 'string') {
              
              try {
                // 첫 번째 파싱: 이중으로 JSON 문자열이 포함된 상태이므로 한 번 파싱
                keywordsArray = JSON.parse(keywordsArray);
                
                // 두 번째 파싱: 첫 번째 파싱이 성공적으로 이루어지면 다시 파싱
                if (typeof keywordsArray === 'string') {
                  keywordsArray = JSON.parse(keywordsArray);
                }
                
              } catch (e) {
                console.error("Error parsing keywords string:", e);
                keywordsArray = [];  // 파싱 실패 시 빈 배열로 처리
              }
            }
          
            // keywordsArray가 배열이 맞는지 확인
            if (!Array.isArray(keywordsArray)) {
              console.error("keywordsArray is not an array. It is:", typeof keywordsArray);
              keywordsArray = [];  // 빈 배열로 처리
            }
          
            // 키워드 필터링
            matchKeywords = searchName === '' || keywordsArray.some(keyword => {
              const match = keyword.toLowerCase().includes(searchName.toLowerCase());
              return match;
            });
          
          } catch (error) {
            console.error("Error parsing keywords:", error);
          }
        }
      return matchTeam && matchGame && (matchName || matchKeywords);
    });
    setFilteredPlayers(newFilteredPlayers);
  };

  const filterTeamsByGame = (gameId) => {
    const teamIds = players
        .filter(player => parseInt(player.game) === parseInt(gameId))
        .map(player => parseInt(player.team_id));
    const uniqueTeamIds = [...new Set(teamIds)];
    const filtered = teams.filter(team => uniqueTeamIds.includes(team.id));
    setFilteredTeams(filtered);
  };


  useEffect(() => {
      if (selectedGame) {
          filterTeamsByGame(selectedGame);
      }
  }, [selectedGame, players, teams]);

  // 필터가 변경될 때마다 handleFilter 함수 호출
  useEffect(() => {
    handleFilter(); // 필터 적용
  }, [players, selectedTeam, selectedGame, searchName]);

  // 게임 선택 시 URL을 업데이트하는 함수
  const handleGameChange = (e) => {
    const selectedGameId = e.target.value;
    setSelectedGame(selectedGameId);

    setSelectedTeam(''); // 팀을 초기화
    setFilteredPlayers(players.filter(player => player.game === selectedGameId)); 

    if (selectedGameId) {
      filterTeamsByGame(selectedGameId);
      navigate(`/game/detail/${selectedGameId}`);
    }
  };

  return (
    <>
      <LayoutClient />
        <div className='client-contents'>
          <div className='inner'>
            <div className='path'>Home / 게임 / {game.name}</div>
            <div className='game-detail'>
                {game && (
                    <div className='info-wrap'>
                        <div className='thumb-wrap'><img src={`${SERVER_URL}${game.banner}`} alt={`${game.name} banner`} /></div>
                        <div className='info'>
                            <p className='title'>{game.name}</p>
                            <p className='description'>{game.description}</p>
                        </div>
                    </div>
                )}
                <div className='filters'>
                  <div className='filter'>
                      <select value={selectedGame} onChange={handleGameChange}>
                      <option value=''>게임 선택</option>
                      {games.map(game => (
                          <option key={game.id} value={game.id}>{game.name}</option>
                      ))}
                      </select>
                      <select value={selectedTeam} onChange={(e) => setSelectedTeam(e.target.value)}>
                        <option value=''>팀 선택</option>
                        {filteredTeams.map(team => {
                          console.log("Rendering team option:", team); // <- 렌더링되는 팀 데이터 확인
                          return (
                              <option key={team.id} value={team.id}>
                                  {team.name}
                              </option>
                          );
                        })}
                      </select>
                  </div>
                  <div className='search'>
                      <input
                      type="text"
                      placeholder="선수명, 이름 검색"
                      value={searchName}
                      onChange={(e) => setSearchName(e.target.value)}
                      />
                      <button onClick={handleFilter}></button>
                  </div>
                </div>
                
                <div className='util-wrap'>
                  <div className='totalCnt'>선수목록 {filteredPlayers.length}명</div>
                </div>

                <div className='list-wrap'>
                  <PlayerList players={filteredPlayers} />
                </div>
            </div>
          </div>
        </div>
      <LayoutFooter />
    </>
  );
};

export default GameDetail;