import React, { useState, useEffect } from 'react';
import { fetchMemberships, deleteMembership } from '../../api';
import { Link } from 'react-router-dom';

import Layout from '../../components/layout/Layout';
import MembershipForm from '../../components/member/MembershipForm';
import MembershipList from '../../components/member/MembershipList';

const App = () => {
  const [memberships, setMemberships] = useState([]);
  const [currentMembershipId, setCurrentMembershipId] = useState(null);

  useEffect(() => {
    console.log("Current memberships state:", memberships);
  }, [memberships]);

  useEffect(() => {
    loadMemberships();
  }, []);

  useEffect(() => {
    if (currentMembershipId !== null) {
    }
  }, [currentMembershipId]);
  
  const loadMemberships = () => {
    fetchMemberships().then((response) => {
      setMemberships(response.data);
    }).catch((error) => {
      console.error("Error loading membership:", error);
    });
  };

  const handleEditMembership = (id) => {
    setCurrentMembershipId(id);
  };

  const handleSaveMembership = () => {
    setCurrentMembershipId(null);
    loadMemberships();
  };

  const handleDeleteMembership = (id) => {
    deleteMembership(id).then(() => {
      loadMemberships();
      if (id === currentMembershipId) {
        setCurrentMembershipId(null);
      }
      window.location.reload();
    }).catch((error) => {
      console.error("Error deleting membership:", error);
    });
  };

  return (
    <>
      <Layout />
      <div className='contents'>
        <div className='titleArea'>회원 관리</div>
        <div className='subCate'>
          <Link to="/dashboard/member/list">회원 리스트</Link>
          <Link to="/dashboard/member/grade" className='selected'>회원 등급 관리</Link>
        </div>

        <div className='list-form'>
          <div className='list-wrap'>
            <div className='title-wrap'>
              <p>등급 리스트</p>
              <a href="/dashboard/member/grade">등급 추가</a>
            </div>
            <MembershipList memberships={memberships} onEdit={handleEditMembership} />
          </div>
          <div className='form-wrap'>
            {currentMembershipId ? (
              <>
                <MembershipForm membershipId={currentMembershipId} onSave={handleSaveMembership}  onDelete={handleDeleteMembership}/>
              </>
            ) : (
              <MembershipForm onSave={handleSaveMembership} />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default App;