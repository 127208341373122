import React, { useState } from 'react';
import CommentForm from './CommentForm';

const CommentItem = ({ comment, onAddReply, chkUser, onDelete }) => {
  const [showReplyForm, setShowReplyForm] = useState(false);

  const handleAddReply = (content, parentId) => {
    onAddReply(content, parentId);
    setShowReplyForm(false);
  };

  const handleDelete = () => {
    onDelete(comment.id, comment.parent_id);  // 댓글 삭제 시 parent_id를 전달
  };

  return (
    <li>
      <div className='info-wrap'>
        <div className='info'>
          <p className='writer'>{comment.author || 'Unknown'}</p>
          <p className='date'>{new Date(comment.created_at).toLocaleString()}</p>
        </div>
        <div className='controll'>
          {comment.user_id === chkUser && (
            <div className="edit-delete-buttons">
              <button className='txt edit'>수정</button>
              <button className='txt delete' onClick={handleDelete}>삭제</button>
            </div>
          )}
          <button onClick={() => setShowReplyForm(!showReplyForm)}>
            {showReplyForm ? <img src={`${process.env.PUBLIC_URL}/img/icon-comment-active.svg`} /> : <img src={`${process.env.PUBLIC_URL}/img/icon-comment.svg`} />}
          </button>
        </div>
      </div>
      <p className='comment-contents'>{comment.content}</p>

      {showReplyForm && (
        <CommentForm postId={comment.post_id} parentId={comment.id} onSubmit={handleAddReply} />
      )}

      {comment.replies && (
        <ul>
          {comment.replies.map(reply => (
            <CommentItem key={reply.id} comment={reply} onAddReply={onAddReply} chkUser={chkUser} onDelete={onDelete} />
          ))}
        </ul>
      )}
    </li>
  );
};

export default CommentItem;