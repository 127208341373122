
import React from 'react';
import CommentList from './CommentList';
import CommentForm from './CommentForm';
import { useNavigate } from 'react-router-dom';
import { reportPost } from '../../api';

const PostDetailUI = ({ post, totalComments, isLiked, handleLikeToggle, handleAddComment, comments, currentUserId, handleDeletePost }) => {
  const navigate = useNavigate();


  const handleReportPost = async () => {
    console.log(post[0].id, currentUserId);
    try {
      const response = await reportPost(post[0].id, currentUserId);
      
      // 서버 응답 상태가 200인 경우 신고 성공
      if (response.status === 200) {
        alert(response.data.message);  // 신고 성공 메시지
      }
    } catch (error) {
      // 400 오류인 경우 이미 신고한 경우 처리
      if (error.response && error.response.status === 400) {
        alert(error.response.data.message);  // 이미 신고한 경우 메시지
      } else {
        console.error('Error reporting post:', error);
        alert('게시글 신고에 실패했습니다. 다시 시도해 주세요.');  // 서버 오류 메시지
      }
    }
  };

  return (
    <>
      <div className='client-contents'>
        {post.length > 0 && (
        <div className='inner'>
            <div className="post-detail">
                <div className='post-title'>
                    <h1>{post[0].title}</h1>
                    <p className='date'>{new Date(post[0].created_at).toLocaleString()}</p>
                </div>
                <div className='post-info'>
                    <p className='writer'>{post[0].author || 'Unknown'}</p>
                    <div className='info-wrap'>
                        <p>조회 {post[0].views}</p>
                        <p>추천 {post[0].likes}</p>
                    </div>
                </div>
                <div className='post-contents'>
                    <div className='contents-inner'>
                         <div dangerouslySetInnerHTML={{ __html: post[0].content }} />
                    </div>
                    <div className="recommand-wrap">
                      <button onClick={handleLikeToggle} className={isLiked ? 'active' : ''}>
                        <img src={`${process.env.PUBLIC_URL}/img/icon-like.svg`} />{post[0].likes}
                      </button>
                      <button onClick={handleReportPost} className='btn-report'><img src={`${process.env.PUBLIC_URL}/img/icon-alert.svg`} />신고</button>
                    </div>
                </div>
                {/* 수정 및 삭제 버튼 */}
                {post[0].user_id === currentUserId && (
                  <div className="edit-delete-buttons">
                    <button onClick={() => navigate(`/community/post/edit/${post[0].id}`)}>수정</button>
                    <button className='delete' onClick={handleDeletePost}>삭제</button>
                  </div>
                )}
                <div className='comment-wrap'>
                  <p className='comment-sum'><img src={`${process.env.PUBLIC_URL}/img/icon-pencil.svg`} />댓글 {'('}{totalComments}{')'}</p>
                  <CommentForm postId={post.id} onSubmit={handleAddComment} />
                  <CommentList comments={comments} onAddReply={handleAddComment} userId={currentUserId} />
                </div>
            </div>
        </div>
        )}
      </div>
    </>
  );
};

export default PostDetailUI;