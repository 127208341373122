// App.js

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './style/common.css';
import { AuthContextProvider } from './context/AuthContext';
import { MediaQueryProvider } from './context/MediaQueryContext';
import PrivateRoute from './components/PrivateRoute';

import AdminRoute from './components/AdminRoute';

// DashBoard
import SetCategoryGame from './dashboard/category/Game';
import SetCategoryTeam from './dashboard/category/Team';
import SetCategoryToolCate from './dashboard/category/ToolCate';

// Player
import PlayerList from './dashboard/player/list';
import PlayerWrite from './dashboard/player/write';
import PlayerModify from './dashboard/player/modify';

// Tool
import ToolList from './dashboard/tool/list';
import ToolWrite from './dashboard/tool/write';
import ToolModify from './dashboard/tool/modify';

// ToolSetting
import ToolSettingList from './dashboard/toolSetting/list';
import ToolSettingWrite from './dashboard/toolSetting/write';
import ToolSettingModify from './dashboard/toolSetting/modify';


// Member
import MemberList from './dashboard/member/list';
import MembershipList from './dashboard/member/grade';
 
// Board
import BoardList from './dashboard/board/list';
import BoardWrite from './dashboard/board/write';
import BoardModify from './dashboard/board/modify';
import PostManageList from './dashboard/board/PostList';

// Client Web
import Main from './clients/Main';

import Login from './clients/auth/Login';
import DiscordCallback from './clients/DiscordCallback';
import GoogleCallback from './clients/GoggleCallback';
import NaverCallback from './clients/NaverCallback';
import Signup from './clients/auth/Signup';
import Profile from './clients/profile/Profile';

import GameList from './clients/game/list';
import GameDetail from './clients/game/detail';

import PlayerDetail from './clients/player/detail';

import CommunityList from './clients/community/list';
import CommunityWrite from './clients/community/write';
import CommunityModify from './clients/community/modify';
import PostDetail from './clients/community/detail';

import CompareList from './clients/compare/list';

import MyPage from './clients/mypage/MyPage';

import ScrollToTop from "./components/ScrollTop";
import AdminLogin from "./components/AdminLogin";
import DashboardMain from "./dashboard/index";

function App() {
  return (
      <Router>
        <ScrollToTop />
        <Routes>
          {/* DashBoard */}
          <Route path='/dashboard' element={<AdminRoute element={<DashboardMain />} />}></Route>
          <Route path='/dashboard/category/game' element={<AdminRoute element={<SetCategoryGame />} />}></Route>
          <Route path='/dashboard/category/team' element={<AdminRoute element={<SetCategoryTeam />} />}></Route>
          <Route path='/dashboard/category/toolCate' element={<AdminRoute element={<SetCategoryToolCate />} />}></Route>
          
          {/* Dashboard > Player */}
          <Route path='/dashboard/player/list' element={<AdminRoute element={<PlayerList />} />}></Route>
          <Route path='/dashboard/player/write' element={<AdminRoute element={<PlayerWrite />} />}></Route>
          <Route path="/dashboard/player/modify/:id" element={<AdminRoute element={<PlayerModify />} />}></Route>

          {/* Dashboard > tool */}
          <Route path='/dashboard/tool/list' element={<AdminRoute element={<ToolList />} />}></Route>
          <Route path='/dashboard/tool/write' element={<AdminRoute element={<ToolWrite />} />}></Route>
          <Route path="/dashboard/tool/modify/:id" element={<AdminRoute element={<ToolModify />} />}></Route>

          {/* Dashboard > toolSetting */}
          <Route path='/dashboard/toolSetting/list' element={<AdminRoute element={<ToolSettingList />} />}></Route>
          <Route path='/dashboard/toolSetting/write' element={<AdminRoute element={<ToolSettingWrite />} />}></Route>
          <Route path="/dashboard/toolSetting/modify/:id" element={<AdminRoute element={<ToolSettingModify />} />}></Route>

          {/* Dashboard > Board */}
          <Route path='/dashboard/board/list' element={<AdminRoute element={<BoardList />} />}></Route>
          <Route path='/dashboard/board/write' element={<AdminRoute element={<BoardWrite />} />}></Route>
          <Route path="/dashboard/board/modify/:id" element={<AdminRoute element={<BoardModify />} />}></Route>
          <Route path="/dashboard/board/PostList" element={<AdminRoute element={<PostManageList />} />}></Route>

          {/* Dashboard > Member */}
          <Route path='/dashboard/member/list' element={<AdminRoute element={<MemberList />} />}></Route>
          <Route path='/dashboard/member/grade' element={<AdminRoute element={<MembershipList />} />}></Route>
          <Route path="/dashboard/login" element={<AdminLogin />} />

          {/* Client*/}
          <Route path="/" element={<Main />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/profile" element={<Profile />} />

          <Route path="/auth/discord/callback" element={<DiscordCallback />} />
          <Route path="/auth/google/callback" element={<GoogleCallback />} />
          <Route path="/auth/naver/callback" element={<NaverCallback />} />

          <Route path="/game" element={<GameList />} />
          <Route path="/game/detail/:id" element={<GameDetail />} />

          <Route path="/player/detail/:id" element={
            <PrivateRoute>
              <PlayerDetail />
            </PrivateRoute>
          } />

          {/* Community Routes */}
          <Route path="/community/:category" element={<CommunityList />} />
          <Route path="/community/write/:categoryId/:parentCategory" element={
            <PrivateRoute>
              <CommunityWrite />
            </PrivateRoute>
          } />
          <Route path="/community/post/edit/:id" element={
            <PrivateRoute>
              <CommunityModify />
            </PrivateRoute>
          } />
          <Route path="/community/post/:id" element={
            <PrivateRoute>
              <PostDetail />
            </PrivateRoute>
          } />
          <Route path="/compare" element={<CompareList />} />
          <Route path="/mypage" element={
            <PrivateRoute>
              <MyPage />
            </PrivateRoute>
          } />
        </Routes>
      </Router>
  )
}

export default App;