import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { createSetting, updateSetting, fetchSettingById } from '../../api';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const SettingForm = ({ gameId, settingId, onSave, onChange, onRemove, index }) => {
  const [previewIcon, setPreviewIcon] = useState(null); 
  const [formData, setFormData] = useState({
    category: '',
    icon: null,
  });
  const [dynamicFields, setDynamicFields] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [currentFieldIndex, setCurrentFieldIndex] = useState(null);
  const [currentField, setCurrentField] = useState({ name: '', type: 'text', value: '', options: [] });

  const prevDataRef = useRef({ settingId: null, formData: {}, dynamicFields: [] });

  useEffect(() => {
    if (settingId) {
      fetchSettingById(settingId).then((response) => {
        const setting = response.data;
        if (setting) {
          setFormData({ category: setting.category, icon: null });
          setPreviewIcon(setting.icon ? `${SERVER_URL}${setting.icon}` : `${SERVER_URL}/uploads/no-image.svg`);
          setDynamicFields(setting.dynamic_fields || []);
        }
      }).catch((error) => {
        console.error("Error fetching setting:", error);
      });
    } else {
      setFormData({ category: '', icon: null });
      setDynamicFields([]);
      setPreviewIcon(null);
    }
  }, [settingId]);

  useEffect(() => {
    if (onChange) {
      const updatedData = { settingId, formData, dynamicFields };
      if (JSON.stringify(updatedData) !== JSON.stringify(prevDataRef.current)) {
        onChange(updatedData);
        prevDataRef.current = updatedData;
      }
    }
  }, [settingId, formData, dynamicFields, onChange]);

  const handleIconChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData({ ...formData, icon: file });
        setPreviewIcon(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleDynamicFieldChange = (e) => {
    const { name, value } = e.target;
    setCurrentField({ ...currentField, [name]: value });
  };

  const handleOptionChange = (index, value) => {
    const updatedOptions = [...currentField.options];
    updatedOptions[index] = value;
    setCurrentField({ ...currentField, options: updatedOptions });
  };

  const handleAddField = () => {
    setShowModal(true);
    setCurrentFieldIndex(null);
    setCurrentField({ name: '', type: 'text', value: '', options: [] });
  };

  const handleEditField = (index) => {
    setShowModal(true);
    setCurrentFieldIndex(index);
    setCurrentField(dynamicFields[index]);
  };

  const handleRemoveField = (index) => {
    setDynamicFields(dynamicFields.filter((_, i) => i !== index));
  };

  const handleModalSave = () => {
    if (currentFieldIndex === null) {
      setDynamicFields([...dynamicFields, currentField]);
    } else {
      const updatedFields = dynamicFields.map((field, i) =>
        i === currentFieldIndex ? currentField : field
      );
      setDynamicFields(updatedFields);
    }
    setShowModal(false);
  };

  return (
    <>
      <form className='setting-form'>
        <div className='input-wrapper'>
          <div className='idx'>
            {index + 1}
            <button type="button" onClick={onRemove} className='delete'>삭제</button>
          </div>
          <div className='inputs'>
            <div className="input-wrap">
              <p className="label">분류명</p>
              <input type="text" name="category" value={formData.category} onChange={handleChange} placeholder="분류명" />
            </div>
            <div className="input-wrap">
              <p className="label">아이콘</p>
              {previewIcon && <img src={previewIcon} className="iconPreview" alt="icon preview" />}
              <label className="file-button">
                <input type="file" name="icon" onChange={handleIconChange} className="displaynone" accept="image/*" />
                {previewIcon ? '변경' : '추가'}
              </label>
            </div>
            <div className="input-wrap field">
              <p className="label">항목</p>
              <div className='field-wrap'>
                {dynamicFields.map((field, index) => (
                  <div key={index} className='field'>
                    <div style={{ flex: 1 }}>
                      <div style={{ display: 'flex' }}>
                        <div className='box'>
                          <input
                            type="text"
                            name="name"
                            value={field.name}
                            onChange={(e) => handleDynamicFieldChange(e)}
                            placeholder="항목명"
                          />
                        </div>
                        <div className='box cont'>
                          <select
                            name="type"
                            value={field.type}
                            onChange={(e) => handleDynamicFieldChange(e)}
                          >
                            <option value="text">자유입력</option>
                            <option value="radio">선택</option>
                          </select>
                          <span>기본값 : </span>
                          {field.type === 'text' && (
                            <input
                              type="text"
                              name="value"
                              value={field.value}
                              onChange={(e) => handleDynamicFieldChange(e)}
                              placeholder="기본값"
                            />
                          )}
                          {field.type === 'radio' && (
                            <div className='input-select'>
                              {field.options.map((option, optIndex) => (
                                <div key={optIndex}>
                                  <input
                                    type="radio"
                                    name={`radio-${index}`}
                                    value={option}
                                    checked={field.value === option}
                                    onChange={(e) => handleDynamicFieldChange(e)}
                                  /> {option}
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <button type="button" onClick={() => handleEditField(index)}>관리</button>
                    <button type="button" onClick={() => handleRemoveField(index)} className='delete'>삭제</button>
                  </div>
                ))}
              </div>
            </div>
            <div className='button-bottom-wrap'>
              <button type="button" onClick={handleAddField}>항목 추가</button>
            </div>    
          </div>
        </div>
      </form>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>항목 설정</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formFieldName">
              <Form.Label>항목명</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={currentField.name}
                onChange={handleDynamicFieldChange}
                placeholder="항목명"
              />
            </Form.Group>
            <Form.Group controlId="formFieldType">
              <Form.Label>값 타입</Form.Label>
              <Form.Control
                as="select"
                name="type"
                value={currentField.type}
                onChange={handleDynamicFieldChange}
              >
                <option value="text">자유입력</option>
                <option value="radio">선택</option>
              </Form.Control>
            </Form.Group>
            {currentField.type === 'text' && (
              <Form.Group controlId="formFieldValue">
                <Form.Label>기본값</Form.Label>
                <Form.Control
                  type="text"
                  name="value"
                  value={currentField.value}
                  onChange={handleDynamicFieldChange}
                  placeholder="기본값"
                />
              </Form.Group>
            )}
            {currentField.type === 'radio' && (
              <Form.Group controlId="formFieldOptions">
                <Form.Label>옵션</Form.Label>
                {currentField.options.map((option, index) => (
                  <div key={index} className="d-flex align-items-center mb-2">
                    <Form.Check
                      type="radio"
                      name="defaultOption"
                      checked={currentField.value === option}
                      onChange={() => setCurrentField({ ...currentField, value: option })}
                      className="mr-2"
                    />
                    <Form.Control
                      type="text"
                      value={option}
                      onChange={(e) => handleOptionChange(index, e.target.value)}
                      placeholder={`Option ${index + 1}`}
                      className="mr-2"
                    />
                    <Button
                      className='delete'
                      variant="danger"
                      onClick={() => {
                        const newOptions = currentField.options.filter((_, i) => i !== index);
                        setCurrentField({ 
                          ...currentField, 
                          options: newOptions, 
                          value: currentField.value === option ? '' : currentField.value // 기본값이 삭제되는 옵션이라면 초기화
                        });
                      }}
                    >
                      삭제
                    </Button>
                  </div>
                ))}
                <Button
                  className='confirm'
                  variant="secondary"
                  onClick={() => setCurrentField({ ...currentField, options: [...currentField.options, ''] })}
                >
                  옵션 추가
                </Button>
              </Form.Group>
            )}
            {currentField.type === 'range' && (
              <div>
                <Form.Group controlId="formFieldMin">
                  <Form.Label>최소값</Form.Label>
                  <Form.Control
                    type="number"
                    name="min"
                    value={currentField.min || ''}
                    onChange={handleDynamicFieldChange}
                    placeholder="최소값"
                  />
                </Form.Group>
                <Form.Group controlId="formFieldMax">
                  <Form.Label>최대값</Form.Label>
                  <Form.Control
                    type="number"
                    name="max"
                    value={currentField.max || ''}
                    onChange={handleDynamicFieldChange}
                    placeholder="최대값"
                  />
                </Form.Group>
                <Form.Group controlId="formFieldValue">
                  <Form.Label>기본값</Form.Label>
                  <Form.Control
                    type="number"
                    name="value"
                    value={currentField.value || ''}
                    onChange={handleDynamicFieldChange}
                    placeholder="기본값"
                  />
                </Form.Group>
              </div>
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" className='confirm' onClick={handleModalSave}>
            저장
          </Button>
          <Button variant="secondary" className='delete' onClick={() => setShowModal(false)}>
            취소
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SettingForm;