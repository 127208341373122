import React, { useEffect, useState } from 'react';
import { fetchPostsCategory, fetchPosts, fetchBoards, fetchUserPenaltyStatus } from '../../api';
import { useNavigate, Link } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
const SERVER_URL = process.env.REACT_APP_SERVER_URL;
const defaultImage = `${SERVER_URL}/uploads/image.gif`;

const PostList = ({ categoryId, posts, boards, parentCategory }) => {
  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  let userId = null;

  // 토큰이 있을 때만 decode
  if (token) {
    try {
      const decodedToken = jwtDecode(token);
      userId = decodedToken.id;
    } catch (error) {
      console.error('Invalid token:', error);
      // 토큰이 잘못된 경우 처리
    }
  }
  const [currentPage, setCurrentPage] = useState(1);
  const postsPerPage = 10;
  const [penaltyStatus, setPenaltyStatus] = useState(null);  // 패널티 상태
  const [penaltyMessage, setPenaltyMessage] = useState('');

  useEffect(() => {
    const checkPenaltyStatus = async () => {
      try {
        const response = await fetchUserPenaltyStatus(userId);
        console.log(response.penalties[0].is_active)
        const is_active = response.penalties[0].is_active;
        const penalty_date = response.penalties[0].penalty_date;

        if (is_active == 1) {
          setPenaltyStatus(true);
          setPenaltyMessage(`신고 누적으로 패널티가 부여되었습니다.\n패널티 부여일자부터 일주일 뒤 작성 가능합니다.\n(패널티 부여 일자: ${new Date(penalty_date).toLocaleString()})`);
        } else {
          setPenaltyStatus(false);
        }
      } catch (error) {
        console.error('Error fetching penalty status:', error);
      }
    };

    if (userId) {
      checkPenaltyStatus();
    }
  }, [userId]);

  const getCategoryName = (id) => {
    const category = boards.find(board => board.id === id);
    return category ? category.name : 'Unknown';
  };

  // 대분류에 대한 하위 카테고리만 필터링
  const filteredPosts = categoryId
    ? posts.filter(post => post.category_id === categoryId)
    : parentCategory
      ? posts.filter(post => {
          // 대분류에 속하는 하위 카테고리만 필터링
          const boardIds = boards.filter(board => board.parent_category === parentCategory).map(board => board.id);
          return boardIds.includes(post.category_id);
        })
      : posts;

  const sortedPosts = filteredPosts.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = sortedPosts.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(sortedPosts.length / postsPerPage); i++) {
    pageNumbers.push(i);
  }

  const formatDate = (dateStr) => {
    const postDate = new Date(dateStr);
    const now = new Date();
    const today = now.toDateString();
    const postDateString = postDate.toDateString();

    return postDate.toLocaleDateString();
  };

  const hasImageTag = (content) => {
    const imgTagRegex = /<img\b[^>]*>/i; // <img> 태그를 찾는 정규식
    return imgTagRegex.test(content);
  };

  const handleWritePost = () => {
    if (penaltyStatus) {
      alert(penaltyMessage);  // 페널티 상태가 있으면 메시지 표시
    } else {
      navigate(`/community/write/${categoryId || 'null'}/${parentCategory || ''}`);
    }
  };

  return (
    <>
      <button
        onClick={handleWritePost}  // 클릭 시 패널티 상태 확인 후 동작
        className='btnWrite'
      >
        글쓰기
      </button>

      <div className='board-table-head'>
        <li className='category'>카테고리</li>
        <li className='subject'>제목</li>
        <li className='writer'>글쓴이</li>
        <li className='date'>날짜</li>
        <li className='hit'>댓글</li>
        <li className='hit'>조회</li>
        <li className='recommand'>추천</li>
      </div>

      <div className='board-inner'>
        <ul>
          {currentPosts.map(post => (
            <li key={post.id}>
              <p className='category'>{getCategoryName(post.category_id)}</p>
              <p className='subject'>
                <Link to={`/community/post/${post.id}`}>
                  {post.title}
                  {hasImageTag(post.content) && (
                    <img src={defaultImage} />
                  )}
                </Link>
              </p>
              <div>
                <p className='writer'>{post.author || 'Unknown'}</p>
                <p className='date'>{formatDate(post.created_at)}</p>
                <p className='hit'><span>댓글</span>{post.commentCount}</p>
                <p className='hit'><span>조회</span>{post.views}</p>
                <p className='recommand'><span>추천</span>{post.likes}</p>
              </div>
            </li>
          ))}
        </ul>
      </div>

      {/* 페이지네이션 버튼 */}
      <div className='pagination'>
        {pageNumbers.map(number => (
          <button key={number} onClick={() => paginate(number)} className={number === currentPage ? 'active' : ''}>
            {number}
          </button>
        ))}
      </div>
    </>
  );
};

export default PostList;