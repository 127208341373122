import React, { useState } from 'react';

const CommentForm = ({ postId, parentId, onSubmit }) => {
  const [content, setContent] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (content.trim()) {
      onSubmit(content, parentId);
      setContent(''); // 입력 필드 초기화
    }
  };

  return (
    <div className='comment-write'>
      <form onSubmit={handleSubmit}>
        <div className='write-wrap'>
          <textarea
            value={content}
            onChange={(e) => setContent(e.target.value)}
            placeholder={parentId ? '댓글을 작성해주세요.' : '댓글을 작성해주세요.'}
          />
          <button type="submit">댓글달기</button>
        </div>
      </form>
    </div>
  );
};

export default CommentForm;